import React from 'react'
import { Datagrid, DateField, EditButton, List, ReferenceField, TextField } from 'react-admin'
import { ItemFilters } from './Filter'
import ItemBulkActionButtons from './BulkButtons'
import useRepostDrawer from '../repost/useRepostDrawer'
import LinkToClient from './LinkToClient'

export const ItemList = (props) => {
  const { renderDrawer, toggleModal } = useRepostDrawer({ ...props })
  return (
    <>
      <List
        {...props}
        sort={{ field: 'endDate', order: 'DESC' }}
        perPage={25}
        filters={<ItemFilters showBidder showSeller />}
        bulkActionButtons={<ItemBulkActionButtons onRepost={toggleModal} />}
      >
        <Datagrid>
          <LinkToClient>
            <TextField source="name" label="Title" />
          </LinkToClient>
          <ReferenceField source="currentBidderId" reference="bidders" label="Bidder">
            <TextField source="username" />
          </ReferenceField>
          <ReferenceField source="sellerId" reference="sellers" label="Seller">
            <TextField source="username" />
          </ReferenceField>
          <ReferenceField source="categoryId" reference="categories" label="Category">
            <TextField source="name" />
          </ReferenceField>
          <TextField source="lotId" label="Lot#" />
          <TextField source="shortId" label="Id" />
          <TextField source="status" />
          <ReferenceField source="invoiceId" reference="invoices" label="Invoice nr" allowEmpty>
            <TextField source="shortId" />
          </ReferenceField>
          <TextField source="assetNumber" label="Asset Number" />
          <DateField source="startDate" showTime />
          <DateField source="endDate" showTime />
          <DateField source="createdAt" />
          <EditButton />
        </Datagrid>
      </List>
      {renderDrawer()}
    </>
  )
}
