import { useCallback } from 'react'
import app from '../../../client/feathersClient'

const useEmails = (invoiceData) => {
  return useCallback(
    async (receiver) => {
      await app.service('generate').create(
        {
          invoiceId: invoiceData._id,
          status: invoiceData.status,
          contacts: [
            {
              type: receiver,
              email: invoiceData[receiver].email,
            },
          ],
        },
        {
          headers: {
            'x-service-method': 'sendInvoiceEmail',
          },
        },
      )
    },
    [invoiceData],
  )
}

export default useEmails
