import {
  Create,
  DateTimeInput,
  NumberInput,
  ReferenceInput,
  required,
  SimpleForm,
  TextInput,
  BooleanInput,
  AutocompleteInput,
  SelectInput,
} from 'react-admin'
import React from 'react'

export const ItemCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" label="Title" validate={required()} />
      <ReferenceInput
        source="sellerId"
        reference="sellers"
        label="Seller"
        validate={required()}
        allowEmpty
        filterToQuery={(searchText) => {
          return (
            searchText && {
              'username[$regex]': searchText,
              'username[$options]': 'i',
            }
          )
        }}
      >
        <AutocompleteInput optionText="username" emptyText="clear search" />
      </ReferenceInput>
      <TextInput source="lotId" label="LotId" validate={required()} />
      <TextInput source="assetNumber" label="AssetNumber" />
      <ReferenceInput
        source="categoryId"
        reference="categories"
        label="Category"
        validate={required()}
        filterToQuery={(searchText) => {
          return (
            searchText && {
              'name[$regex]': searchText,
              'name[$options]': 'i',
            }
          )
        }}
      >
        <AutocompleteInput optionText="name" emptyText="clear search" />
      </ReferenceInput>
      <SelectInput source="status" choices={[{ id: 'internal', name: 'internal' }]} allowEmpty />
      <DateTimeInput source="startDate" validate={required()} />
      <DateTimeInput source="endDate" validate={required()} />
      <NumberInput
        min={0}
        step={0.01}
        format={(value) => Number(value) / 100}
        parse={(value) => value * 100}
        source="initialPrice"
        label="Initial Price"
        validate={required()}
      />
      <BooleanInput source="medicalLicenceRequirement" label="Medical Licence Requirement" />
    </SimpleForm>
  </Create>
)
