import { useUpdate } from 'react-admin'

const mapStatuses = {
  inactive: 'invoiced',
  invoiced: 'inactive',
  paid: 'invoiced',
}

const useInvoiceStatus = (invoiceData) => {
  const [path] = useUpdate('invoices', invoiceData._id, { status: mapStatuses[invoiceData.status] }, invoiceData)
  return path
}

export default useInvoiceStatus
