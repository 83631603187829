import polyglotI18nProvider from 'ra-i18n-polyglot'
import englishMessages from './i18n/en'
import messages from './i18n'

export default polyglotI18nProvider((locale) => {
  if (messages[locale]) return messages[locale]

  // fallback on english
  return englishMessages
}, 'en')
