import React, { useMemo } from 'react'
import {
  Create,
  TextInput,
  SimpleForm,
  required,
  Toolbar,
  translate,
  ReferenceInput,
  AutocompleteInput,
  DateInput,
  DateTimeInput,
} from 'react-admin'
import Button from '@material-ui/core/Button'
import RichTextInput from 'ra-input-rich-text'
import Typography from '@material-ui/core/Typography'
import CustomSaveButton from './CustomSaveButton'
import MultiRepostToolbar from './MultiRepostToolbar'
import flatten from 'lodash/flatten'
import { makeStyles } from '@material-ui/core/styles'

const CustomToolbar = translate(({ onCancel, ...props }) => {
  return (
    <Toolbar {...props}>
      <CustomSaveButton payload={props.payload} onFinish={onCancel} />
      <Button onClick={onCancel}>Cancel</Button>
    </Toolbar>
  )
})

const useStyles = makeStyles({
  container: {
    zIndex: 4000,
  },
})

const RepostCreateDrawer = ({ onCancel, ...props }) => {
  const classes = useStyles()
  const allData = useMemo(
    () =>
      flatten([props.data]).map((item) => ({
        ...item,
        status: 'published',
        startDate: new Date(),
        endDate: new Date(),
      })),
    [props.data],
  )
  const multiple = allData.length > 1
  const data = allData[0]
  return (
    <Create {...props} record={{ ...data }}>
      <SimpleForm
        toolbar={
          multiple ? (
            <MultiRepostToolbar onCancel={onCancel} payload={props} data={allData} />
          ) : (
            <CustomToolbar onCancel={onCancel} payload={props} />
          )
        }
      >
        <Typography variant="h6">{`Repost ${allData.length} items`}</Typography>
        <Typography variant="subtitle2">{multiple && `Filled with first item selected`}</Typography>
        <Typography variant="body1">{!multiple && data.name}</Typography>
        <DateTimeInput source="startDate" showTime validate={required()} fullWidth />
        <DateTimeInput source="endDate" showTime validate={required()} fullWidth />
        <Typography variant="h6">Removal Information</Typography>
        <TextInput source="removal.email" label="Email" fullWidth />
        <TextInput source="removal.companyName" label="Company Name" fullWidth />
        <TextInput source="removal.firstName" label="First Name" fullWidth />
        <TextInput source="removal.lastName" label="Last Name" fullWidth />
        <TextInput source="removal.address1" label="Address 1" fullWidth />
        <TextInput source="removal.address2" label="Address 2" fullWidth />
        <ReferenceInput
          source="removal.locationId"
          reference="locations"
          label="Location"
          sort={{
            field: 'city',
            order: 'ASC',
          }}
          filterToQuery={(searchText) => {
            return (
              searchText && {
                'city[$regex]': searchText,
                'city[$options]': 'i',
              }
            )
          }}
          allowEmpty
          fullWidth
        >
          <AutocompleteInput
            optionText={(record) => {
              if (!record?._id) {
                return 'clear search'
              }
              return `${record.city}, ${record.state}`
            }}
          />
        </ReferenceInput>
        <TextInput source="removal.zip" label="Zip" fullWidth />
        <DateInput source="removal.date" label="Date" fullWidth />
        <DateInput source="removal.phone" label="Phone" fullWidth />
        <RichTextInput source="removal.description" label="Description" multiline fullWidth />
        <Typography variant="h6">Inspection Information</Typography>
        <ReferenceInput
          source="inspection.locationId"
          reference="locations"
          label="InspectionLocation"
          sort={{
            field: 'city',
            order: 'ASC',
          }}
          filterToQuery={(searchText) => {
            return (
              searchText && {
                'city[$regex]': searchText,
                'city[$options]': 'i',
              }
            )
          }}
          allowEmpty
          fullWidth
        >
          <AutocompleteInput
            options={{
              suggestionsContainerProps: {
                className: classes.container,
              },
            }}
            optionText={(record) => {
              if (!record?._id) {
                return 'clear search'
              }
              return `${record.city}, ${record.state}`
            }}
          />
        </ReferenceInput>
        <DateInput source="inspection.date" label="Date" fullWidth />
        <RichTextInput source="inspection.description" label="Inspection Description" multiline fullWidth />
      </SimpleForm>
    </Create>
  )
}

export default RepostCreateDrawer
