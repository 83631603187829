import React from 'react'
import {
  SimpleForm,
  Create,
  TextInput,
  Edit,
  List,
  Datagrid,
  TextField,
  EditButton,
  Filter,
  DateField,
  TabbedForm,
  FormTab,
  required,
} from 'react-admin'
import DateFilters from '../../elements/DateFilters'
import HeaderTextField from '../../elements/HeaderTextField'
import Toolbar from '../../elements/Toolbar'

export const Filters = (props) => (
  <Filter {...props}>
    <TextInput label="Email" source="email.$regex" alwaysOn />
    {DateFilters}
  </Filter>
)

export const AdminCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="username" label="Username" type="username" validate={required()} />
      <TextInput source="email" label="Email" type="email" validate={required()} />
      <TextInput source="password" label="Password" type="password" validate={required()} />
    </SimpleForm>
  </Create>
)

export const AdminEdit = (props) => (
  <Edit {...props} title={<HeaderTextField source="username" label="Admin:" />}>
    <TabbedForm redirect={false} toolbar={<Toolbar />}>
      <FormTab label="summary">
        <TextField source="_id" label="Id" />
        <TextInput source="username" label="Username" type="text" validate={required()} />
        <TextInput source="email" label="Email" type="text" validate={required()} />
        <TextInput source="password" label="Password" type="password" validate={required()} />
        <DateField source="createdAt" label="Created at" showTime />
        <DateField source="updatedAt" label="Updated at" showTime />
      </FormTab>
    </TabbedForm>
  </Edit>
)

export const AdminList = (props) => (
  <List {...props} sort={{ field: 'createdAt', order: 'DESC' }} perPage={25} filters={<Filters />}>
    <Datagrid>
      <TextField source="username" label="username" />
      <TextField source="email" label="email" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
      <EditButton />
    </Datagrid>
  </List>
)
