import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Filter,
  Create,
  SimpleForm,
  TextInput,
  required,
  Edit,
  TabbedForm,
  FormTab,
  EditButton,
} from 'react-admin'
import DateFilters from '../../elements/DateFilters'
import HeaderTextField from '../../elements/HeaderTextField'
import Toolbar from '../../elements/Toolbar'

export const Filters = (props) => <Filter {...props}>{DateFilters}</Filter>

export const CategoryCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required()} />
      <TextInput source="description" />
    </SimpleForm>
  </Create>
)

export const CategoryEdit = (props) => (
  <Edit {...props} title={<HeaderTextField source="name" label="Category:" />}>
    <TabbedForm redirect={false} toolbar={<Toolbar />}>
      <FormTab label="summary">
        <TextField source="_id" label="Id" />
        <TextInput source="name" validate={required()} />
        <TextInput source="description" />
        <DateField source="createdAt" label="Created at" showTime />
        <DateField source="updatedAt" label="Updated at" showTime />
      </FormTab>
    </TabbedForm>
  </Edit>
)

export const CategoryList = (props) => (
  <List {...props} sort={{ field: 'createdAt', order: 'DESC' }} perPage={25} filters={<Filters />}>
    <Datagrid>
      <TextField source="_id" />
      <TextField source="name" />
      <TextField source="description" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
      <EditButton />
    </Datagrid>
  </List>
)
