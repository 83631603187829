import {
  Create,
  SimpleForm,
  FileInput,
  NumberInput,
  required,
  ReferenceInput,
  DateTimeInput,
  TextField,
  useInput,
  AutocompleteInput,
  SelectInput,
} from 'react-admin'

import React from 'react'
import Papa from 'papaparse'
import { Typography } from '@material-ui/core'

export const allowHeaders = [
  'title',
  'lot number',
  'category',
  'price',
  'description',
  'require medical license',
  'asset numbers',
]

const keysMap = {
  title: 'name',
  lotnumber: 'lotId',
  lotNumber: 'lotId',
  category: 'categoryId',
  price: 'initialPrice',
  description: 'description',
  requiremedicallicense: 'medicalLicenceRequirement',
  requireMedicalLicense: 'medicalLicenceRequirement',
  requiremedicallicence: 'medicalLicenceRequirement',
  requireMedicalLicence: 'medicalLicenceRequirement',
  assetnumbers: 'assetNumber',
  assetNumbers: 'assetNumber',
}
const trimable = ['lotId', 'categoryId', 'initialPrice', 'medicalLicenceRequirement']

const CustomFileInput = (props) => {
  const {
    input: { onChange },
  } = useInput(props)

  const parse = (fileToParse) => {
    if (!fileToParse) return null
    Papa.parse(fileToParse, {
      header: true,
      transformHeader: (header) => keysMap[header.toLowerCase().replaceAll(' ', '')] || '',
      transform: (el, name) => {
        if (trimable.includes(name)) {
          return el.trim()
        }
        return el
      },
      complete: (parsedData) => {
        if (parsedData) {
          onChange(parsedData.data)
        }
      },
    })
  }

  return (
    <>
      <Typography variant={'body2'}>{`Allowed headers (case insensitive, white spaces are ignored):`}</Typography>
      <Typography variant={'subtitle2'}>{allowHeaders.map((e) => `"${e}"`).join(', ')}</Typography>
      <FileInput accept={'.csv'} onChange={parse} {...props}>
        <TextField source={'lotId'} />
      </FileInput>
    </>
  )
}

export const AuctionsCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <CustomFileInput source={'file'}>
          <TextField source={'blobUrl'} />
        </CustomFileInput>

        <ReferenceInput
          source="sellerId"
          reference="sellers"
          label="Seller"
          validate={required()}
          sort={{
            field: 'username',
            order: 'ASC',
          }}
          filterToQuery={(searchText) => {
            return (
              searchText && {
                'username[$regex]': searchText,
                'username[$options]': 'i',
              }
            )
          }}
          allowEmpty
        >
          <AutocompleteInput optionText="username" emptyText="clear search" />
        </ReferenceInput>
        <SelectInput source="status" choices={[{ id: 'internal', name: 'internal' }]} allowEmpty />
        <DateTimeInput source="startDate" validate={required()} />
        <DateTimeInput source="endDate" validate={required()} />
        <NumberInput
          source={'interval'}
          helperText={'time [s] to separate end each item'}
          defaultValue={60}
          validate={required()}
        />
      </SimpleForm>
    </Create>
  )
}
