import { storageKey } from './constants'
import decodeJwt from 'jwt-decode'
import { checkAuth } from './checkAuth'

export const getIdentity = (client) => async () => {
  await checkAuth(client)
  const jwtToken = localStorage.getItem(storageKey)
  const decodedToken = decodeJwt(jwtToken)
  const user = await client.service('users').get(decodedToken.sub)
  return user
}
