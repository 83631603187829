import React from 'react'
import {
  Edit,
  FormTab,
  ListButton,
  TabbedForm,
  TextField,
  TopToolbar,
  DateField,
  ArrayField,
  Datagrid,
  UrlField,
  Labeled,
  useNotify,
} from 'react-admin'
import HeaderTextField from '../../elements/HeaderTextField'
import { Button } from '@material-ui/core'
import client from '../../client/feathersClient'
import Toolbar from '../../elements/Toolbar'

const Actions = ({ basePath }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
  </TopToolbar>
)

const HtmlField = (props) => {
  return (
    <Labeled label={props.label} fullWidth>
      <div dangerouslySetInnerHTML={{ __html: props.record.html }} />
    </Labeled>
  )
}

export default (props) => {
  const notify = useNotify()
  return (
    <Edit {...props} undoable={false} actions={<Actions />} title={<HeaderTextField source="name" label="Email:" />}>
      <TabbedForm redirect={false} toolbar={<Toolbar />}>
        <FormTab label="summary">
          <TextField source="_id" />
          <TextField source="name" />
          <TextField source="status" />
          <TextField source="errorMessage" />
          <TextField source="from" />
          <TextField source="to" />
          <TextField source="subject" />
          <HtmlField label="HTML" />
          <TextField source="text" />
          <ArrayField source="attachments">
            <Datagrid>
              <TextField source="filename" />
              <UrlField source="path" target="_blank" />
            </Datagrid>
          </ArrayField>
          <DateField source="createdAt" showTime />
          <DateField source="updatedAt" showTime />
          <Button
            color={'primary'}
            onClick={async () => {
              try {
                await client.service('emails').patch(props.id, { resend: true })
                notify('Done!', 'info', {}, false, 3000)
              } catch (e) {
                notify('Error while sending.', 'error', {}, false, 3000)
              }
            }}
          >
            Resend Email
          </Button>
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}
