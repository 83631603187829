import React from 'react'
import { BulkDeleteButton } from 'react-admin'
import RepeatIcon from '@material-ui/icons/Repeat'
import feathersClient from '../../../client/feathersClient'
import { Button } from 'react-admin'

const ItemBulkActionButtons = (props) => {
  return (
    <>
      <Button
        label={'Repost'}
        onClick={async () => {
          const { data: items } = await feathersClient.service('items').find({
            query: {
              _id: { $in: props.selectedIds },
              $limit: 10000,
            },
          })
          await props.onRepost(items)
        }}
      >
        <RepeatIcon />
      </Button>
      <BulkDeleteButton {...props} />
    </>
  )
}

export default ItemBulkActionButtons
