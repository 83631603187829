import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Filter,
  DateField,
  TextInput,
  FunctionField,
  SelectInput,
} from 'react-admin'
import DateFilters from '../../elements/DateFilters'

const CustomFilter = (props) => (
  <Filter {...props}>
    <TextInput label="name" source="name.$regex" alwaysOn />
    <TextInput label="to" source="to.$regex" />
    <TextInput label="subject" source="subject.$regex" />
    <SelectInput
      source="status"
      choices={[
        { id: 'pending', name: 'pending' },
        { id: 'complete', name: 'complete' },
        { id: 'failed', name: 'failed' },
      ]}
    />
    {DateFilters}
  </Filter>
)

export default (props) => (
  <List {...props} sort={{ field: 'createdAt', order: 'DESC' }} perPage={25} filters={<CustomFilter />}>
    <Datagrid>
      <TextField source="name" />
      <TextField source="to" />
      <TextField source="from" />
      <TextField source="subject" />
      <FunctionField label="Attachments" render={(record) => (record.attachments ? record.attachments.length : 0)} />
      <TextField source="status" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
      <EditButton />
    </Datagrid>
  </List>
)
