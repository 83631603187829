import React from 'react'
import {
  Create,
  NumberInput,
  SimpleForm,
  required,
  Toolbar,
  translate,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import CustomSaveButton from './CustomSaveButton'
import { makeStyles } from '@material-ui/core/styles'

const CustomToolbar = translate(({ onCancel, ...props }) => {
  return (
    <Toolbar {...props}>
      <CustomSaveButton payload={props.payload} onFinish={onCancel} />
      <Button onClick={onCancel}>Cancel</Button>
    </Toolbar>
  )
})

const useStyles = makeStyles({
  container: {
    zIndex: 4000,
  },
})

const ManualCreateDrawer = ({ onCancel, ...props }) => {
  const classes = useStyles()
  return (
    <Create {...props} record={props.data}>
      <SimpleForm toolbar={<CustomToolbar onCancel={onCancel} payload={props} />}>
        <Typography variant="h6">Sell manually</Typography>
        <Typography variant="body1">{props.data.name}</Typography>
        <NumberInput
          min={0}
          step={0.01}
          format={(value) => value / 100}
          parse={(value) => Math.round(value * 100)}
          source={'initialPrice'}
          validate={required()}
        />
        <ReferenceInput
          source="currentBidderId"
          reference="bidders"
          label="Bidder"
          validate={required()}
          sort={{
            field: 'username',
            order: 'ASC',
          }}
          filterToQuery={(searchText) => {
            return (
              searchText && {
                'username[$regex]': searchText,
                'username[$options]': 'i',
              }
            )
          }}
          allowEmpty
        >
          <AutocompleteInput
            options={{
              suggestionsContainerProps: {
                className: classes.container,
              },
            }}
            optionText="username"
            emptyText="clear search"
          />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  )
}

export default ManualCreateDrawer
