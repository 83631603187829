import React, { useMemo } from 'react'
import {
  Filter,
  Edit,
  TabbedForm,
  useUpdate,
  Toolbar,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
  DateInput,
  NumberInput,
  SaveButton,
  useNotify,
  useRefresh,
} from 'react-admin'
import Button from '@material-ui/core/Button'
import get from 'lodash/get'
import DateFilters from '../../elements/DateFilters'
import HeaderTextField from '../../elements/HeaderTextField'
import ItemTab from './ItemsTab'
import ActionTab from './ActionTab'
import BuyerInfoTab from './BuyerInfoTab'
import SellerInfoTab from './SellerInfoTab'
import { useFormState } from 'react-final-form'
import RemovalTab from './RemovalTab'

export const InvoiceFilters = ({ showBidder, showSeller, ...props }) => (
  <Filter {...props}>
    <NumberInput label="Number" source="shortId" alwaysOn />
    <SelectInput
      source="status"
      key="status"
      alwaysOn
      choices={[
        { id: 'published', name: 'published' },
        { id: 'invoiced', name: 'invoiced' },
        { id: 'paid', name: 'paid' },
        { id: 'unsold', name: 'unsold' },
        { id: 'endEarly', name: 'endEarly' },
        { id: 'internal', name: 'internal' },
        { id: 'inactive', name: 'inactive' },
        { id: 'endInternal', name: 'endInternal' },
      ]}
    />
    {showSeller ? (
      <ReferenceInput
        label={'Seller username'}
        source="sellerId"
        reference="sellers"
        sort={{
          field: 'username',
          order: 'ASC',
        }}
        filterToQuery={(searchText) => {
          return (
            searchText && {
              'username[$regex]': searchText,
              'username[$options]': 'i',
            }
          )
        }}
        allowEmpty
      >
        <AutocompleteInput optionText="username" emptyText="clear search" />
      </ReferenceInput>
    ) : null}
    {showSeller ? (
      <ReferenceInput
        label={'Seller email'}
        source="sellerId"
        reference="sellers"
        sort={{
          field: 'email',
          order: 'ASC',
        }}
        filterToQuery={(searchText) => {
          return (
            searchText && {
              'email[$regex]': searchText,
              'email[$options]': 'i',
            }
          )
        }}
        allowEmpty
      >
        <AutocompleteInput optionText="email" emptyText="clear search" />
      </ReferenceInput>
    ) : null}
    {showBidder ? (
      <ReferenceInput
        label={'Bidder email'}
        source="bidderId"
        reference="bidders"
        sort={{
          field: 'email',
          order: 'ASC',
        }}
        filterToQuery={(searchText) => {
          return (
            searchText && {
              'email[$regex]': searchText,
              'email[$options]': 'i',
            }
          )
        }}
        allowEmpty
      >
        <AutocompleteInput optionText="email" emptyText="clear search" />
      </ReferenceInput>
    ) : null}
    {showBidder ? (
      <ReferenceInput
        label={'Bidder username'}
        source="bidderId"
        reference="bidders"
        sort={{
          field: 'username',
          order: 'ASC',
        }}
        filterToQuery={(searchText) => {
          return (
            searchText && {
              'username[$regex]': searchText,
              'username[$options]': 'i',
            }
          )
        }}
        allowEmpty
      >
        <AutocompleteInput optionText="username" emptyText="clear search" />
      </ReferenceInput>
    ) : null}
    <DateInput source="payBefore" key="payBefore" />
    <DateInput source="payAfter" key="payAfter" />
    {DateFilters}
  </Filter>
)

const SaveB = (props) => {
  const notify = useNotify()
  const refresh = useRefresh()
  const formSate = useFormState()

  const toPatch = {}
  Object.keys(formSate.dirtyFields).forEach((key) => {
    if (!key.includes('items') || key === 'items') {
      toPatch[key] = get(formSate.values, key)
      if (key === 'items') {
        // make name field required (again)
        toPatch[key] = toPatch[key]?.filter((item) => !!item.name)
      }
    }
  })

  const [path] = useUpdate('invoices', props.record.id, toPatch, props.record)

  return (
    <Button
      onClick={async () => {
        path(
          {},
          {
            onSuccess: () => {
              refresh()
              notify('Saved!', 'info', {}, false, 3000)
            },
            onFailure: (e) => notify(e, 'error', {}, false, 3000),
          },
        )
      }}
      color={'primary'}
      variant={'contained'}
      {...props}
    >
      Save
    </Button>
  )
}

const CustomSaveToolbar = (props) => {
  return (
    <Toolbar {...props}>
      {props.saveVisible === 'custom' && <SaveB />}
      {props.saveVisible === 'default' && <SaveButton />}
    </Toolbar>
  )
}

export const InvoiceEdit = (props) => {
  const saveVisible = useMemo(() => {
    const path = props.location.pathname.split('/').pop()
    return isNaN(Number(path)) ? 'custom' : path === '1' ? 'none' : 'default'
  }, [props.location.pathname])

  return (
    <Edit {...props} title={<HeaderTextField source="shortId" label="Invoice #:" />}>
      <TabbedForm redirect={false} toolbar={<CustomSaveToolbar saveVisible={saveVisible} />}>
        <ItemTab {...props} />
        <ActionTab {...props} />
        <BuyerInfoTab {...props} />
        <SellerInfoTab {...props} />
        <RemovalTab {...props} />
      </TabbedForm>
    </Edit>
  )
}
