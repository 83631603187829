import ManualCreateDrawer from './ManualCreateDrawer'
import { Drawer as MuiDrawer } from '@material-ui/core'
import React, { useCallback, useState } from 'react'

const Drawer = ({ isOpen, toggle, data, ...props }) => {
  return (
    <MuiDrawer open={isOpen} anchor="right" onClose={toggle}>
      <ManualCreateDrawer onCancel={toggle} data={data} {...props} />
    </MuiDrawer>
  )
}

const useManualSellDrawer = (props) => {
  const [isOpen, setOpen] = useState(false)
  const [data, setData] = useState()

  const toggle = useCallback(
    (props) => {
      if (!isOpen) setData(props)
      setOpen(!isOpen)
    },
    [isOpen, data],
  )

  return {
    toggleModal: toggle,
    renderDrawer: () => <Drawer isOpen={isOpen} toggle={toggle} data={data} {...props} />,
  }
}

export default useManualSellDrawer
