import React from 'react'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'

const DrawerButton = ({ children, ...props }) => {
  return (
    <Button
      startIcon={<AddIcon />}
      color="primary"
      onClick={() => {
        props.handleClick(props.record)
      }}
      {...props}
    >
      {children}
    </Button>
  )
}

export default DrawerButton
