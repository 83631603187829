import { Typography } from '@material-ui/core'
import get from 'lodash/get'
import React from 'react'

const PriceField = ({ source, record, label, labelOnValue = true, style, ...props }) => {
  const value = get(record, source)

  return (
    <div style={{ ...style }}>
      <Typography component="span" variant="caption" color="textSecondary">
        {labelOnValue && label}
      </Typography>
      <div style={{ padding: '8px 0px 4px 0px' }}>
        <Typography component="span" variant="body2" {...props}>
          {(Number(value || 0) / 100).toFixed(2)}
        </Typography>
      </div>
    </div>
  )
}

export default PriceField
