import { Datagrid, DateField, EditButton, List, TextField, Filter, TextInput } from 'react-admin'
import React from 'react'
import DateFilters from '../../elements/DateFilters'
import ShowNestedRouteButton from '../../elements/ShowNestedRouteButton'

export const Filters = (props) => (
  <Filter {...props}>
    <TextInput label="Company name" source="accounting.companyName.$regex" />
    <TextInput label="Username" source="username.$regex" alwaysOn />
    <TextInput label="Email" source="email.$regex" />
    <TextInput label="First Name" source="accounting.firstName.$regex" />
    <TextInput label="Last Name" source="accounting.lastName.$regex" />
    {DateFilters}
  </Filter>
)

export const SellerList = (props) => (
  <List {...props} sort={{ field: 'createdAt', order: 'DESC' }} perPage={25} filters={<Filters />}>
    <Datagrid>
      <TextField source="username" label="Username" />
      <TextField source="removal.companyName" label="Company Name" />
      <TextField source="removal.firstName" label="First Name" />
      <TextField source="removal.lastName" label="Last Name" />
      <TextField source="email" label="email" />
      <DateField source="createdAt" label="Created at" showTime />
      <ShowNestedRouteButton mainPath="sellers" nestedPath="items">
        Show items
      </ShowNestedRouteButton>
      <ShowNestedRouteButton mainPath="sellers" nestedPath="invoices">
        Show invoices
      </ShowNestedRouteButton>
      <EditButton />
    </Datagrid>
  </List>
)
