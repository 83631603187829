import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  Filter,
  DateField,
  Edit,
  TabbedForm,
  FormTab,
  TextInput,
  required,
  EditButton,
} from 'react-admin'
import DateFilters from '../../elements/DateFilters'
import HeaderTextField from '../../elements/HeaderTextField'
import Toolbar from '../../elements/Toolbar'

export const Filters = (props) => <Filter {...props}>{DateFilters}</Filter>

export const StripeCustomersEdit = (props) => (
  <Edit {...props} title={<HeaderTextField source="name" label="Stripe Customer:" />}>
    <TabbedForm redirect={false} toolbar={<Toolbar />}>
      <FormTab label="summary">
        <TextField source="_id" label="Id" />
        <TextInput source="name" validate={required()} />
        <TextInput source="email" validate={required()} />
        <TextInput source="address.city" label="City" validate={required()} />
        <TextInput source="address.line1" label="Address 1" validate={required()} />
        <TextInput source="address.line2" label="Address 2" />
        <TextInput source="address.postal_code" label="Zip" validate={required()} />
        <TextInput source="address.country" label="Country" validate={required()} />
        <TextInput source="address.state" label="State" validate={required()} />
        <TextInput source="phone" />
        <DateField source="createdAt" label="Created at" showTime />
        <DateField source="updatedAt" label="Updated at" showTime />
      </FormTab>
    </TabbedForm>
  </Edit>
)

export const StripeCustomersList = (props) => (
  <List {...props} sort={{ field: 'createdAt', order: 'DESC' }} perPage={25} filters={<Filters />}>
    <Datagrid>
      <ReferenceField source="bidderId" reference="bidders" label="Bidder">
        <TextField source="username" />
      </ReferenceField>
      <TextField source="email" label="Email" />
      <TextField source="name" label="Name" />
      <TextField source="phone" label="Phone" />
      <TextField source="_id" label="Id" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
      <EditButton />
    </Datagrid>
  </List>
)
