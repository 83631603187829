import {
  Datagrid,
  DateField,
  EditButton,
  FormTab,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  TextField,
} from 'react-admin'
import PriceField from '../../../elements/PriceField'
import React from 'react'

const InvoicesTab = (props) => {
  return (
    <FormTab {...props} label="Invoices" path="invoices">
      <ReferenceManyField
        addLabel={false}
        fullWidth
        reference="invoices"
        target="bidderId"
        filter={{ bidderId: props.record?._id }}
        pagination={<Pagination />}
      >
        <Datagrid>
          <TextField source={'shortId'} label={'Number'} />
          <ReferenceField source="sellerId" reference="sellers" label="Seller">
            <TextField source="username" />
          </ReferenceField>
          <TextField source="status" />
          <PriceField source="summary.bidderTotal" label="Total price" labelOnValue={false} />
          <DateField source="paymentDate" label="Pay at" showTime />
          <EditButton />
        </Datagrid>
      </ReferenceManyField>
    </FormTab>
  )
}

export default InvoicesTab
