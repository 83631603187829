import React, { useMemo } from 'react'

const TimeZoneOffset = () => {
  const offsetInHours = useMemo(() => {
    const x = new Date()
    const offset = (x.getTimezoneOffset() / 60) * -1

    if (offset > 0) return `+${offset}`
    if (offset < 0) return `-${offset * -1}`
    return '0'
  }, [])

  return <div>{offsetInHours} GMT</div>
}

TimeZoneOffset.defaultProps = {
  addLabel: true,
}

export default TimeZoneOffset
