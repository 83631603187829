import { Datagrid, DateField, FormTab, Pagination, ReferenceManyField, TextField } from 'react-admin'
import EditExternalButton from '../../../elements/EditExternalButton'
import React from 'react'

const StripeCustomerTab = (props) => {
  return (
    <FormTab {...props} label="Stripe Customers" path="stripe/customers">
      <ReferenceManyField
        addLabel={false}
        fullWidth
        reference="stripe/customers"
        target="bidderId"
        perPage={10}
        pagination={<Pagination />}
        sort={{
          field: 'createdAt',
          order: 'DESC',
        }}
      >
        <Datagrid>
          <TextField source="email" label="Email" />
          <TextField source="name" label="Name" />
          <TextField source="_id" />
          <DateField source="createdAt" showTime />
          <DateField source="updatedAt" showTime />
          <EditExternalButton reference="_id" service="stripe/customers">
            Edit
          </EditExternalButton>
        </Datagrid>
      </ReferenceManyField>
    </FormTab>
  )
}

export default StripeCustomerTab
