import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Filter,
  Create,
  SimpleForm,
  TextInput,
  required,
  Edit,
  TabbedForm,
  FormTab,
  EditButton,
} from 'react-admin'
import DateFilters from '../../elements/DateFilters'
import Toolbar from '../../elements/Toolbar'

export const Filters = (props) => <Filter {...props}>{DateFilters}</Filter>

export const FaqCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="question" validate={required()} multiline fullWidth />
      <TextInput source="answer" validate={required()} multiline fullWidth />
    </SimpleForm>
  </Create>
)

export const FaqEdit = (props) => (
  <Edit {...props}>
    <TabbedForm redirect={false} toolbar={<Toolbar />}>
      <FormTab label="summary">
        <TextField source="_id" label="Id" />
        <TextInput source="question" validate={required()} multiline fullWidth />
        <TextInput source="answer" validate={required()} multiline fullWidth />
        <DateField source="createdAt" label="Created at" showTime />
        <DateField source="updatedAt" label="Updated at" showTime />
      </FormTab>
    </TabbedForm>
  </Edit>
)

export const FaqList = (props) => (
  <List {...props} sort={{ field: 'createdAt', order: 'DESC' }} perPage={25} filters={<Filters />}>
    <Datagrid>
      <TextField source="question" />
      <TextField source="answer" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
      <EditButton />
    </Datagrid>
  </List>
)
