import { storageKey } from './constants'

const REDIRECT_TO = '/login'

export const checkAuth = (client) => (params) => {
  const hasJwtInStorage = !!localStorage.getItem(storageKey)
  const hasReAuthenticate =
    Object.getOwnPropertyNames(client).includes('reAuthenticate') && typeof client.reAuthenticate === 'function'

  if (hasJwtInStorage && hasReAuthenticate) {
    return client
      .reAuthenticate()
      .then(() => Promise.resolve())
      .catch(() => Promise.reject({ redirectTo: REDIRECT_TO }))
  }

  return hasJwtInStorage ? Promise.resolve() : Promise.reject({ redirectTo: REDIRECT_TO })
}
