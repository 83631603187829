import Typography from '@material-ui/core/Typography'
import styled from 'styled-components'

const Text = styled(Typography)`
  background: rgba(0, 0, 0, 0.04);
  cursor: pointer;
  width: 236px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgba(0, 0, 0, 0.54);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  margin: 10px 0 !important;
  padding: 10px 10px;
`
export default Text
