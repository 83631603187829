import React from 'react'
import { Link } from 'react-admin'
import Button from '@material-ui/core/Button'
import EditIcon from '@material-ui/icons/Edit'

const EditExternalButton = (props) => {
  return (
    <Link
      to={{
        pathname: `/${props.service}/${props.record.id}`,
      }}
    >
      <Button startIcon={<EditIcon />}>{props.children}</Button>
    </Link>
  )
}

export default EditExternalButton
