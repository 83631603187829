import { Edit, TabbedForm, useNotify } from 'react-admin'
import React, { useEffect, useState } from 'react'
import app from '../../../client/feathersClient'
import HeaderTextField from '../../../elements/HeaderTextField'
import Toolbar from '../../../elements/Toolbar'
import SummaryTab from './SummaryTab'
import InvoicesTab from './InvoicesTab'
import ItemsTab from './ItemsTab'
import StripeCustomerTab from './StripeCustomerTab'
import StripePaymentTab from './StripePaymentTab'
import StripeIntentsTab from './StripeIntentsTab'
import SessionsTab from './SessionsTab'
import WatchListTab from './WatchListTab'

const BidderEdit = (props) => {
  const notify = useNotify()
  const [bidder, setBidder] = useState()
  useEffect(() => {
    const getInitialData = async () => {
      try {
        const bidderRes = await app.service('bidders').get(props.id)
        setBidder(bidderRes)
      } catch (e) {
        notify(e.message, 'error')
      }
    }
    getInitialData()
  }, [])

  return (
    <Edit {...props} title={<HeaderTextField source="username" label="Bidder:" />}>
      <TabbedForm redirect={false} toolbar={<Toolbar />}>
        <SummaryTab bidder={bidder} />
        <InvoicesTab />
        <ItemsTab />
        <StripeCustomerTab />
        <StripePaymentTab />
        <StripeIntentsTab />
        <SessionsTab />
        <WatchListTab />
      </TabbedForm>
    </Edit>
  )
}

export default BidderEdit
