import styled from 'styled-components'
import React from 'react'
import { orange } from '@material-ui/core/colors'

const A = styled('a')`
  color: ${() => orange[700]};
  text-decoration: none;
`

const LinkToClient = (props) => {
  return (
    <A href={`${process.env.REACT_APP_CLIENT_URL}/items/${props.record._id}`} target={'_blank'}>
      {React.cloneElement(props.children, props)}
    </A>
  )
}

export default LinkToClient
