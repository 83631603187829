import * as React from 'react'
import { useSelector } from 'react-redux'
import { DashboardMenuItem, Menu, MenuItemLink, getResources, usePermissions } from 'react-admin'
import DefaultIcon from '@material-ui/icons/ViewList'
import startCase from 'lodash/startCase'

const checkCommonPermissions = (allowed = [], obtained = []) => {
  if (obtained.includes('all')) return true
  if (!allowed?.length) return false
  return allowed.some((allow) => obtained.includes(allow))
}

export const CustomMenu = (props) => {
  const { permissions } = usePermissions()
  const resources = useSelector(getResources)
  const open = useSelector((state) => state.admin.ui.sidebarOpen)

  return (
    <Menu {...props}>
      <DashboardMenuItem style={{ marginTop: 24 }} />
      {resources.map((resource) => {
        if (!checkCommonPermissions(resource.options?.permissions, permissions)) return null
        if (!resource.hasList) return null
        return (
          <MenuItemLink
            key={resource.name}
            to={`/${resource.name}`}
            primaryText={(resource.options && resource.options.label) || startCase(resource.name)}
            leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
            onClick={props.onMenuClick}
            sidebarIsOpen={open}
          />
        )
      })}
    </Menu>
  )
}
