import { Datagrid, DateField, FormTab, Pagination, ReferenceManyField, TextField } from 'react-admin'
import React from 'react'
import ShowExternalButton from '../../../elements/ShowExternalButtons'

const StripePaymentTab = (props) => {
  return (
    <FormTab {...props} label="Stripe Payment Methods" path="stripe/payment-methods">
      <ReferenceManyField
        addLabel={false}
        fullWidth
        reference="stripe/payment-methods"
        target="bidderId"
        perPage={10}
        pagination={<Pagination />}
        sort={{
          field: 'createdAt',
          order: 'DESC',
        }}
      >
        <Datagrid>
          <TextField source="_id" />
          <DateField source="createdAt" showTime />
          <DateField source="updatedAt" showTime />
          <ShowExternalButton reference="_id" service="stripe/payment-methods">
            Show details
          </ShowExternalButton>
        </Datagrid>
      </ReferenceManyField>
    </FormTab>
  )
}

export default StripePaymentTab
