import React, { Children, cloneElement, isValidElement, useRef } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import Button from '@material-ui/core/Button'
import FormHelperText from '@material-ui/core/FormHelperText'
import { makeStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/AddCircleOutline'
import { ValidationError } from 'ra-core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import FormInput from './FormInput'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: 0,
      marginBottom: 0,
      '& > li:last-child': {
        borderBottom: 'none',
      },
    },
    line: {
      display: 'flex',
      listStyleType: 'none',
      borderBottom: `solid 1px ${theme.palette.divider}`,
      [theme.breakpoints.down('xs')]: { display: 'block' },
      '&.fade-enter': {
        opacity: 0.01,
        transform: 'translateX(100vw)',
      },
      '&.fade-enter-active': {
        opacity: 1,
        transform: 'translateX(0)',
        transition: 'all 500ms ease-in',
      },
      '&.fade-exit': {
        opacity: 1,
        transform: 'translateX(0)',
      },
      '&.fade-exit-active': {
        opacity: 0.01,
        transform: 'translateX(100vw)',
        transition: 'all 500ms ease-in',
      },
    },
    index: {
      width: '3em',
      paddingTop: '1em',
      [theme.breakpoints.down('sm')]: { display: 'none' },
    },
    form: { flex: 2 },
    action: {
      paddingTop: '0.5em',
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
    deleteButton: {
      background: 'transparent',
      color: '#f44336',
      boxShadow: 'none',
    },
    addButton: {
      background: 'transparent',
      color: '#3f51b5',
      boxShadow: 'none',
    },
  }),
  { name: 'RaSimpleFormIterator' },
)

const CustomFormIterator = (props) => {
  const {
    basePath,
    children,
    fields,
    meta: { error, submitFailed },
    record,
    resource,
    source,
    disableAdd,
    disableRemove,
    variant,
    margin,
    defaultValue,
  } = props
  const classes = useStyles(props)
  // We need a unique id for each field for a proper enter/exit animation
  // so we keep an internal map between the field position and an auto-increment id
  const nextId = useRef(fields && fields.length ? fields.length : defaultValue ? defaultValue.length : 0)

  // We check whether we have a defaultValue (which must be an array) before checking
  // the fields prop which will always be empty for a new record.
  // Without it, our ids wouldn't match the default value and we would get key warnings
  // on the CssTransition element inside our render method
  const ids = useRef(nextId.current > 0 ? Array.from(Array(nextId.current).keys()) : [])

  const removeField = (index) => () => {
    ids.current.splice(index, 1)
    fields.remove(index)
  }

  // Returns a boolean to indicate whether to disable the remove button for certain fields.
  // If disableRemove is a function, then call the function with the current record to
  // determining if the button should be disabled. Otherwise, use a boolean property that
  // enables or disables the button for all of the fields.
  const disableRemoveField = (record, disableRemove) => {
    if (typeof disableRemove === 'boolean') {
      return disableRemove
    }
    return disableRemove && disableRemove(record)
  }

  const addField = () => {
    ids.current.push(nextId.current++)
    fields.push({ isVirtual: true })
  }

  const records = get(record, source)
  return fields ? (
    <TableContainer component={Paper}>
      {submitFailed && typeof error !== 'object' && error && (
        <FormHelperText error>
          <ValidationError error={error} />
        </FormHelperText>
      )}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Lot #</TableCell>
            <TableCell>Short Id</TableCell>
            <TableCell>Sales [$]</TableCell>
            <TableCell>Net [$]</TableCell>
            <TableCell>Buyer's Premium [$]</TableCell>
            <TableCell>Tax [$]</TableCell>
            <TableCell>Commission [$]</TableCell>
            <TableCell>Subtotal [$]</TableCell>
            <TableCell>Non taxable</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {fields.map((member, index) => (
            <TableRow>
              <>
                {Children.map(children, (input, index2) =>
                  isValidElement(input) ? (
                    <TableCell>
                      <FormInput
                        basePath={input.props.basePath || basePath}
                        input={cloneElement(input, {
                          source: input.props.source ? `${member}.${input.props.source}` : member,
                          index: input.props.source ? undefined : index2,
                          label:
                            typeof input.props.label === 'undefined'
                              ? input.props.source
                                ? `resources.${resource}.fields.${input.props.source}`
                                : undefined
                              : input.props.label,
                        })}
                        record={(records && records[index]) || {}}
                        resource={resource}
                        variant={variant}
                        margin={margin}
                      />
                    </TableCell>
                  ) : null,
                )}
                {!disableRemoveField((records && records[index]) || {}, disableRemove) && (
                  <TableCell>
                    <Button
                      variant="contained"
                      className={classes.deleteButton}
                      startIcon={<DeleteIcon />}
                      onClick={removeField(index)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                )}
              </>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {!disableAdd && (
        <li className={classes.line} style={{ padding: '10px 8px' }}>
          <Button variant="contained" className={classes.addButton} startIcon={<AddIcon />} onClick={addField}>
            Add item
          </Button>
        </li>
      )}
    </TableContainer>
  ) : null
}

CustomFormIterator.defaultProps = {
  disableAdd: false,
  disableRemove: false,
}

CustomFormIterator.propTypes = {
  defaultValue: PropTypes.any,
  basePath: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  fields: PropTypes.object,
  meta: PropTypes.object,
  record: PropTypes.object,
  source: PropTypes.string,
  resource: PropTypes.string,
  translate: PropTypes.func,
  disableAdd: PropTypes.bool,
  disableRemove: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  TransitionProps: PropTypes.shape({}),
}

export default CustomFormIterator
