import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  DateField,
  Filter,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin'
import DateFilters from '../../elements/DateFilters'

export const Filters = (props) => (
  <Filter {...props}>
    <ReferenceInput
      source="bidderId"
      reference="bidders"
      label="Bidder"
      filterToQuery={(searchText) => {
        return (
          searchText && {
            'username[$regex]': searchText,
            'username[$options]': 'i',
          }
        )
      }}
      allowEmpty
    >
      <AutocompleteInput optionText="username" emptyText="clear search" allowEmpty />
    </ReferenceInput>
    <ReferenceInput
      source="itemId"
      reference="items"
      label="Lot Id"
      filterToQuery={(searchText) => {
        return (
          searchText && {
            'lotId[$regex]': searchText,
            'lotId[$options]': 'i',
          }
        )
      }}
      allowEmpty
      sort={{
        field: 'lotId',
        order: 'ASC',
      }}
    >
      <AutocompleteInput source="lotId" emptyText="clear search" allowEmpty />
    </ReferenceInput>
    {DateFilters}
  </Filter>
)

export const WatchListList = (props) => (
  <List {...props} sort={{ field: 'createdAt', order: 'DESC' }} perPage={25} filters={<Filters />}>
    <Datagrid>
      <ReferenceField source="bidderId" reference="bidders" label="Bidder">
        <TextField source="username" />
      </ReferenceField>
      <ReferenceField source="itemId" reference="items" label="Item">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
  </List>
)
