import { FormTab, ReferenceField, TextField, TextInput } from 'react-admin'
import React from 'react'
import CanvasDraw from 'react-canvas-draw'
import Labeled from '../../elements/CustomFormInterator/Labeled'

const BuyerInfoTab = (props) => {
  return (
    <FormTab {...props} label="Buyer Info" path="buyer-info">
      <ReferenceField source="bidderId" reference="bidders" label="Username">
        <TextField source="username" />
      </ReferenceField>
      <TextInput source="bidder.name" label="Name" />
      <TextInput source="bidder.email" label="Email" />
      <TextInput source="bidder.phone" label="Phone" />
      <TextInput source="bidder.address1" label="Address Line 1" />
      <TextInput source="bidder.address2" label="Address Line 2" />
      <TextInput source="bidder.city" label="City" />
      <TextInput source="bidder.state" label="State" />
      <TextInput source="bidder.zip" label="Zip" />

      <Labeled label={'signature'}>
        <CanvasDraw disabled hideGrid saveData={props.record?.signature} />
      </Labeled>
    </FormTab>
  )
}

export default BuyerInfoTab
