import React, { useEffect, useState } from 'react'
import { Typography, Button, Switch } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import LotIdList from '../../LotIdTable'
import 'react-virtualized/styles.css'
import { List } from 'react-virtualized'
import Text from './Text'
import useValidateLotIds from './useValidateLotIds'
import renderElement from './Element'

const Input = ({ widget, formProps, values = [], rest }) => {
  const props = rest
  const [images, setImages] = useState(props.record[props.source])
  const [isUpdateNext, setUpdateNext] = useState(true)
  const { validateLotIds, match } = useValidateLotIds(props.record)

  useEffect(() => {
    setImages([...images, ...values])
    if (props.withLotId) validateLotIds([...images, ...values])
    formProps.input.onChange([...images, ...values])
  }, [values])

  const changeItem = (index, data, changeNext) => {
    let copy = [...images]
    copy.splice(index, 1, { ...copy[index], ...data })
    if (changeNext && copy.length > index + 1) {
      copy = copy.map((img, newIndex) => {
        if (index < newIndex) {
          return { ...copy[newIndex], ...data }
        }
        return img
      })
    }
    if (data.isMain) {
      copy.forEach((img, newIndex) => {
        if (img.lotId === copy[index].lotId && index !== newIndex) {
          img.isMain = false
        }
      })
    }
    if (data.isCode) {
      let mainImg = copy.find((img) => img.lotId === copy[index].lotId && img.isMain)
      if (!mainImg) {
        copy.forEach((img, newIndex) => {
          if (img.lotId === copy[index].lotId) {
            img.isMain = index + 1 === newIndex
          }
        })
      }
      mainImg = copy.find((img) => img.lotId === copy[index].lotId && img.isMain)
      if (!mainImg) {
        copy.find((img, newIndex) => {
          if (img.lotId === copy[index].lotId && newIndex !== index) {
            img.isMain = true
            return true
          }
        })
      }
    }
    setImages(copy)
    if (props.withLotId) validateLotIds(copy)
    formProps.input.onChange(copy)
  }

  const removeElement = (index, withLotId = true) => {
    if (index === null) {
      setImages([])
      formProps.input.onChange([])
      return
    }
    const copy = [...images]
    copy.splice(index, 1)
    setImages(copy)
    if (withLotId) validateLotIds(copy)
    formProps.input.onChange(copy)
  }

  return (
    <>
      <List
        rowCount={images.length}
        rowHeight={360}
        height={800}
        width={1200}
        rowRenderer={renderElement({ images, changeItem, isUpdateNext, removeElement, withLotId: props.withLotId })}
      />
      <Text onClick={widget.open}>
        {`Upload Image ${props.required ? '*' : ''}`}
        <AddIcon color="inherit" />
      </Text>
      <Button variant="contained" onClick={() => removeElement(null, props.withLotId)}>
        Remove All
      </Button>
      {props.withLotId && (
        <>
          <div
            style={{
              margin: '16px 0',
            }}
          >
            <Switch
              checked={isUpdateNext}
              onChange={() => setUpdateNext(!isUpdateNext)}
              name={'"LotId" updates will change all images bellow.'}
            />
            <Typography component={'span'}>{'"LotId" updates will change all images bellow.'}</Typography>
          </div>
          <LotIdList data={match} />
        </>
      )}
    </>
  )
}

export default Input
