import { Create, required, SimpleForm, TextInput } from 'react-admin'
import React from 'react'

const BidderCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="username" label="Username" validate={required()} />
      <TextInput source="email" label="Email" type="email" validate={required()} />
      <TextInput source="password" label="Password" type="password" validate={required()} />
    </SimpleForm>
  </Create>
)

export default BidderCreate
