import {
  DateField,
  ListButton,
  NumberInput,
  ReferenceField,
  required,
  Edit,
  TabbedForm,
  FormTab,
  TextField,
  TopToolbar,
} from 'react-admin'
import React from 'react'
import Toolbar from '../../elements/Toolbar'

const Actions = ({ basePath }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
  </TopToolbar>
)

export const MaxBidEdit = (props) => (
  <Edit {...props} actions={<Actions />}>
    <TabbedForm toolbar={<Toolbar />}>
      <FormTab label="summary">
        <TextField source="_id" label="Id" />
        <NumberInput
          min={0}
          step={0.01}
          format={(value) => Number(value) / 100}
          parse={(value) => value * 100}
          source="value"
          label="Value"
          validate={required()}
        />
        <ReferenceField source="bidderId" reference="bidders">
          <TextField source="username" />
        </ReferenceField>
        <ReferenceField source="itemId" reference="items">
          <TextField source="name" />
        </ReferenceField>
        <DateField source="createdAt" label="Created at" showTime />
        <DateField source="updatedAt" label="Updated at" showTime />
      </FormTab>
    </TabbedForm>
  </Edit>
)

export default MaxBidEdit
