import React from 'react'
import { AppBar } from 'react-admin'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { orange } from '@material-ui/core/colors'

const useStyles = makeStyles({
  toolbar: {
    flex: 1,
    justifyContent: 'space-between',
  },
})

const MyAppBar = (props) => {
  const classes = useStyles()
  return (
    <AppBar {...props}>
      <Toolbar className={classes.toolbar}>
        <Typography variant="h6" id="react-admin-title" />
        <a href={`${process.env.REACT_APP_CLIENT_URL}`} target="_blank" style={{ textDecoration: 'none' }}>
          <Button variant="outlined" style={{ color: orange[200] }}>
            Visit Surplusmarketplace.com
          </Button>
        </a>
      </Toolbar>
    </AppBar>
  )
}

export default MyAppBar
