import styled from 'styled-components'
import { blueGrey, deepOrange, green, indigo, purple, red } from '@material-ui/core/colors'

const statusColor = (status) => {
  switch (status) {
    case 'paid':
      return purple[500]
    case 'published':
      return indigo[500]
    case 'unsold':
      return red[500]
    case 'endEarly':
      return deepOrange[500]
    case 'invoiced':
      return green[500]
    default:
      return blueGrey[500]
  }
}

const StatusBadge = styled('div')`
  background: ${(props) => statusColor(props.status)};
  padding: 2px 5px;
  border-radius: 5px;
  width: fit-content;
  color: white;
  margin: auto;
`

export default StatusBadge
