import {
  AutocompleteInput,
  Create,
  DateInput,
  FormTab,
  NumberInput,
  ReferenceInput,
  SelectInput,
  TabbedForm,
  TextInput,
  required,
} from 'react-admin'

import React from 'react'
import RichTextInput from 'ra-input-rich-text'
import Typography from '@material-ui/core/Typography'

export const SellerCreate = (props) => (
  <Create {...props}>
    <TabbedForm>
      <FormTab label="summary">
        <TextInput source="username" label="Username" validate={required()} />
        <TextInput
          source="email"
          label="Email"
          type="email"
          validate={required()}
          helperText={'Accounting email. Seller can login with this email.'}
        />
        <TextInput source="password" label="Password" type="password" validate={required()} />
        <NumberInput source="commission" label="Commission [%]" validate={required()} />
        <NumberInput source="buyerPremium" label="Buyer's Premium [%]" />
        <ReferenceInput source="salesTaxId" reference="stripe/taxes" label="Sales Tax" allowEmpty>
          <SelectInput optionText={'percentage'} />
        </ReferenceInput>
      </FormTab>

      <FormTab label="Information">
        <Typography variant="h6">Removal Information</Typography>
        <Typography component="span" variant="body2">
          If you left any field empty, we will copy data from accounting into this field.
        </Typography>
        <TextInput source="removal.email" label="Email" validate={required()} />
        <TextInput source="removal.companyName" label="Company Name" validate={required()} />
        <TextInput source="removal.firstName" label="First Name" validate={required()} />
        <TextInput source="removal.lastName" label="Last Name" validate={required()} />
        <TextInput source="removal.address1" label="Address 1" />
        <TextInput source="removal.address2" label="Address 2" />
        <ReferenceInput
          source="removal.locationId"
          reference="locations"
          label="Location"
          sort={{
            field: 'city',
            order: 'ASC',
          }}
          filterToQuery={(searchText) => {
            return (
              searchText && {
                'city[$regex]': searchText,
                'city[$options]': 'i',
              }
            )
          }}
          validate={required()}
        >
          <AutocompleteInput
            optionText={(record) => {
              if (!record?._id) {
                return 'clear search'
              }
              return `${record.city}, ${record.state}`
            }}
          />
        </ReferenceInput>
        <TextInput source="removal.zip" label="Zip" />
        <TextInput source="removal.date" label="Date" />
        <TextInput source="removal.phone" label="Phone" />
        <RichTextInput source="removal.description" label="Description" multiline fullWidth />
        <Typography variant="h6">Inspection Information</Typography>
        <ReferenceInput
          source="inspection.locationId"
          reference="locations"
          label="Location"
          sort={{
            field: 'city',
            order: 'ASC',
          }}
          filterToQuery={(searchText) => {
            return (
              searchText && {
                'city[$regex]': searchText,
                'city[$options]': 'i',
              }
            )
          }}
          allowEmpty
        >
          <AutocompleteInput
            optionText={(record) => {
              if (!record?._id) {
                return 'clear search'
              }
              return `${record.city}, ${record.state}`
            }}
          />
        </ReferenceInput>
        <DateInput source="inspection.date" label="Date" />
        <RichTextInput source="inspection.description" label="Description" multiline fullWidth />
        <Typography variant="h6">Redistribution Information</Typography>
        <TextInput source="redistribution.email" label="Redistribution Email" />
        <RichTextInput source="redistribution.terms" label="Redistribution Terms" multiline fullWidth />
        <Typography variant="h6">Other Information</Typography>
        <RichTextInput source="termsAndConditions" label="Terms And Conditions" multiline fullWidth />
        <RichTextInput source="additionalInfo" label="Additional Info" multiline fullWidth />
      </FormTab>

      <FormTab label="Accounting">
        <TextInput source="accounting.companyName" label="Company Name" />
        <TextInput source="accounting.firstName" label="First Name" />
        <TextInput source="accounting.lastName" label="Last Name" />
        <TextInput source="accounting.address1" label="Address 1" />
        <TextInput source="accounting.address2" label="Address 2" />
        <ReferenceInput
          source="accounting.locationId"
          reference="locations"
          label="Location"
          sort={{
            field: 'city',
            order: 'ASC',
          }}
          filterToQuery={(searchText) => {
            return (
              searchText && {
                'city[$regex]': searchText,
                'city[$options]': 'i',
              }
            )
          }}
          allowEmpty
        >
          <AutocompleteInput
            optionText={(record) => {
              if (!record?._id) {
                return 'clear search'
              }
              return `${record.city}, ${record.state}`
            }}
          />
        </ReferenceInput>
        <TextInput source="accounting.zip" label="Zip" />
        <TextInput source="accounting.phone" label="Phone" />
        <TextInput source="accounting.taxNumber" label="Tax Number" />
        <TextInput source="accounting.sellerInfo" multiline fullWidth label={'Seller Info'} />
      </FormTab>
    </TabbedForm>
  </Create>
)
