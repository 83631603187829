import {
  Datagrid,
  DateField,
  DateTimeInput,
  Edit,
  FormTab,
  NumberInput,
  Pagination,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  required,
  SelectInput,
  ShowButton,
  EditButton,
  TabbedForm,
  TextField,
  TextInput,
  BooleanInput,
  BooleanField,
  AutocompleteInput,
  FormDataConsumer,
  DateInput,
  ArrayInput,
  SimpleFormIterator,
  EmailField,
  RichTextField,
} from 'react-admin'
import MultipleImageInput from '../../elements/MultipleImageInput'
import React from 'react'
import TimeZoneOffset from '../../elements/TimeZoneOffset'
import PriceField from '../../elements/PriceField'
import RichTextInput from 'ra-input-rich-text'
import Typography from '@material-ui/core/Typography'
import HeaderTextField from '../../elements/HeaderTextField'
import useRepostDrawer from './repost/useRepostDrawer'
import DrawerButton from '../../elements/DrawerButton'
import useManualSellDrawer from './sellManually/useManualSellDrawer'
import Toolbar from '../../elements/Toolbar'

export const ItemEdit = (props) => {
  const { renderDrawer, toggleModal } = useRepostDrawer(props)
  const { renderDrawer: renderManualSellDrawer, toggleModal: toggleManualSellDrawer } = useManualSellDrawer(props)

  return (
    <>
      <Edit {...props} title={<HeaderTextField source="name" label="Item:" />}>
        <TabbedForm redirect={false} toolbar={<Toolbar />}>
          <FormTab label="summary">
            <TextField source="_id" label="_id" />
            <TextField source="shortId" label="Id" />
            <TextField source="auctionId" />
            <TextInput source="name" label="Title" validate={required()} />

            <TextInput source="lotId" label="LotId" validate={required()} />
            <TextInput source="assetNumber" label="AssetNumber" />
            <ReferenceField source="sellerId" reference="sellers" label="Seller" validate={required()}>
              <TextField source="username" />
            </ReferenceField>
            <ReferenceField source="invoiceId" reference="invoices" label="Invoice">
              <TextField source="shortId" />
            </ReferenceField>
            <DateField source="createdAt" label="Created at" showTime />
            <DateField source="updatedAt" label="Updated at" showTime />
            <DrawerButton
              handleClick={toggleModal}
              reference="_id"
              service="items"
              disable={props.record?.status !== 'unsold'}
            >
              Repost item
            </DrawerButton>
            <DrawerButton handleClick={toggleManualSellDrawer} reference="_id" service="items">
              Sell manually
            </DrawerButton>
          </FormTab>
          <FormTab label="Details">
            <ReferenceInput
              source="categoryId"
              reference="categories"
              label="Category"
              validate={required()}
              filterToQuery={(searchText) => {
                return (
                  searchText && {
                    'name[$regex]': searchText,
                    'name[$options]': 'i',
                  }
                )
              }}
            >
              <AutocompleteInput optionText="name" emptyText="clear search" />
            </ReferenceInput>
            <NumberInput source="buyerPremium" label="Buyer's Premium [%]" />
            <BooleanInput source="medicalLicenceRequirement" label="Medical Licence Requirement" />
            <BooleanInput source="isFeatured" label="Is Featured" />
            <SelectInput
              source="status"
              choices={[
                { id: 'published', name: 'published' },
                { id: 'invoiced', name: 'invoiced' },
                { id: 'paid', name: 'paid' },
                { id: 'unsold', name: 'unsold' },
                { id: 'endEarly', name: 'endEarly' },
                { id: 'sellingManually', name: 'sellingManually' },
                { id: 'internal', name: 'internal' },
                { id: 'endInternal', name: 'endInternal' },
              ]}
              validate={required()}
            />
            <RichTextInput source="endDescription" label="Description for end internal item" multiline fullWidth />
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                if (formData.status === 'endEarly') {
                  return <TextInput source="endReason" {...rest} />
                }
              }}
            </FormDataConsumer>
            <ReferenceInput source="salesTaxId" reference="stripe/taxes" allowEmpty>
              <SelectInput
                optionText="percentage"
                helperText={'If empty, sales tax will apply from invoice (see Stripe Taxes -> used for all invoices)'}
              />
            </ReferenceInput>
            <br />
            <TextInput source="currency" label="Currency" />
            {/* <ArrayInput source='pictures'>
              <SimpleFormIterator>
                <ImageInput />
                <BooleanInput source={'isMain'} label={'is Main'} />
                <BooleanInput source={'isCode'} label={'is Code'} />
              </SimpleFormIterator>
            </ArrayInput> */}
            <MultipleImageInput source="pictures" withLotId={false} />
          </FormTab>
          <FormTab label="Information">
            <Typography variant="h6">Removal Information</Typography>
            <TextInput source="removal.email" label="Email" />
            <TextInput source="removal.companyName" label="Company Name" />
            <TextInput source="removal.firstName" label="First Name" />
            <TextInput source="removal.lastName" label="Last Name" />
            <TextInput source="removal.address1" label="Address 1" />
            <TextInput source="removal.address2" label="Address 2" />
            <ReferenceInput
              source="removal.locationId"
              reference="locations"
              label="Location"
              sort={{
                field: 'city',
                order: 'ASC',
              }}
              filterToQuery={(searchText) => {
                return (
                  searchText && {
                    'city[$regex]': searchText,
                    'city[$options]': 'i',
                  }
                )
              }}
              allowEmpty
            >
              <AutocompleteInput
                optionText={(record) => {
                  if (!record?._id) {
                    return 'clear search'
                  }
                  return `${record.city}, ${record.state}`
                }}
              />
            </ReferenceInput>
            <TextInput source="removal.zip" label="Zip" />
            <DateInput source="removal.date" label="Date" />
            <TextInput source="removal.phone" label="Phone" />
            <RichTextInput source="removal.description" label="Description" multiline fullWidth />
            <Typography variant="h6">Inspection Information</Typography>
            <ReferenceInput
              source="inspection.locationId"
              reference="locations"
              label="Location"
              sort={{
                field: 'city',
                order: 'ASC',
              }}
              filterToQuery={(searchText) => {
                return (
                  searchText && {
                    'city[$regex]': searchText,
                    'city[$options]': 'i',
                  }
                )
              }}
              allowEmpty
            >
              <AutocompleteInput
                optionText={(record) => {
                  if (!record?._id) {
                    return 'clear search'
                  }
                  return `${record.city}, ${record.state}`
                }}
              />
            </ReferenceInput>
            <DateInput source="inspection.date" label="Date" />
            <RichTextInput source="inspection.description" label="Description" multiline fullWidth />
            <Typography variant="h6">Redistribution Information</Typography>
            <TextInput source="redistribution.email" label="Redistribution Email" />
            <RichTextInput source="redistribution.terms" label="Redistribution Terms" multiline fullWidth />
            <Typography variant="h6">Other Information</Typography>
            <RichTextInput source="termsAndConditions" label="Terms And Conditions" multiline fullWidth />
            <RichTextInput source="additionalInfo" label="Additional Info" multiline fullWidth />
            <RichTextInput source="description" label="Description" multiline fullWidth />
            <ArrayInput source="videoUrls">
              <SimpleFormIterator>
                <TextInput source={'url'} />
              </SimpleFormIterator>
            </ArrayInput>
          </FormTab>
          <FormTab label="Bids">
            <TimeZoneOffset label="Local time offset" />
            <DateTimeInput source="startDate" validate={required()} />
            <DateTimeInput source="endDate" validate={required()} />
            <NumberInput
              min={0}
              step={0.01}
              format={(value) => Number(value) / 100}
              parse={(value) => value * 100}
              source="initialPrice"
              label="Initial Price"
              validate={required()}
            />
            <PriceField source="currentValue" label="Current value" />
            <ReferenceField source="currentBidId" reference="bids" link="show">
              <TextField source="_id" />
            </ReferenceField>
            <ReferenceField source="currentMaxBidId" reference="maxBids" link="edit">
              <TextField source="_id" />
            </ReferenceField>
            <ReferenceField source="currentBidderId" reference="bidders" link="edit">
              <TextField source="username" />
            </ReferenceField>
            <ReferenceManyField
              addLabel={false}
              fullWidth
              reference="bids"
              target="itemId"
              perPage={10}
              pagination={<Pagination />}
              sort={{
                field: 'createdAt',
                order: 'DESC',
              }}
            >
              <Datagrid>
                <TextField source="_id" />
                <PriceField source="value" />
                <ReferenceField source="bidderId" reference="bidders" label="Bidder">
                  <TextField source="username" />
                </ReferenceField>
                <BooleanField source="isAuto" />
                <DateField source="createdAt" label="Created at" showTime />
                <ShowButton />
              </Datagrid>
            </ReferenceManyField>
          </FormTab>
          <FormTab label="Max Bids">
            <ReferenceManyField
              addLabel={false}
              fullWidth
              reference="maxBids"
              target="itemId"
              perPage={10}
              pagination={<Pagination />}
              sort={{
                field: 'createdAt',
                order: 'DESC',
              }}
            >
              <Datagrid>
                <TextField source="_id" />
                <PriceField source="value" />
                <ReferenceField source="bidderId" reference="bidders" label="Bidder">
                  <TextField source="username" />
                </ReferenceField>
                <DateField source="createdAt" label="Created at" showTime />
                <EditButton />
              </Datagrid>
            </ReferenceManyField>
          </FormTab>
        </TabbedForm>
      </Edit>
      {renderDrawer()}
      {renderManualSellDrawer()}
    </>
  )
}
