const changeAngle = (url, params, right = true) => {
  const regex = new RegExp(/(?<=a_)-*\d+/g)
  const found = regex.exec(params)?.[0]
  if (!found) {
    params = 'a_0'
    url = url.replace('/image/upload/', `/image/upload/${params}/`)
  }
  const angle = Number(found) || 0
  let newAngel = angle + (right ? 90 : -90)
  while (Math.abs(newAngel) >= 360) {
    newAngel -= 360 * Math.sign(newAngel)
  }
  const newParams = params.replace(regex, newAngel)
  const newUrl = url.replace(regex, newAngel)

  return {
    url: newUrl.replace('a_0', ''),
    params: newParams.replace('a_0', ''),
  }
}

export default changeAngle
