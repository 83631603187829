import React from 'react'
import { Link } from 'react-admin'
import Button from '@material-ui/core/Button'
import VisibilityIcon from '@material-ui/icons/Visibility'

const ShowNestedRouteButton = (props) => {
  return (
    <Link
      to={{
        pathname: `/${props.mainPath}/${props.record.id}/${props.nestedPath}`,
      }}
    >
      <Button startIcon={<VisibilityIcon />} color="primary">
        {props.children}
      </Button>
    </Link>
  )
}

export default ShowNestedRouteButton
