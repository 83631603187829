import { useState } from 'react'

const useValidateLotIds = (record) => {
  const [match, setMatch] = useState({})

  const validateLotIds = (images) => {
    const newMatch = {}
    images.forEach((img) => {
      const lotId = img.lotId
      newMatch[lotId] = {
        times: (newMatch[lotId]?.times || 0) + 1,
        isValid: !!record.file.find((file) => file.lotId === lotId),
      }
    })
    setMatch(newMatch)
  }

  return {
    validateLotIds,
    match,
  }
}

export default useValidateLotIds
