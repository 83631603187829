import * as React from 'react'
import { SaveButton, Toolbar as RaToolbar, DeleteWithConfirmButton } from 'react-admin'

const Toolbar = (props) => (
  <RaToolbar
    {...props}
    style={{
      display: 'flex',
      justifyContent: 'space-between',
    }}
  >
    <SaveButton />
    <DeleteWithConfirmButton />
  </RaToolbar>
)

export default Toolbar
