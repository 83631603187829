import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Filter,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  TabbedForm,
  FormTab,
  EditButton,
  DateInput,
} from 'react-admin'
import DateFilters from '../../elements/DateFilters'
import RichTextInput from 'ra-input-rich-text'
import Toolbar from '../../elements/Toolbar'
import CloudinaryFileInput from '../../elements/CloudinaryFileInput'
import Typography from '@material-ui/core/Typography'

export const Filters = (props) => <Filter {...props}>{DateFilters}</Filter>

const RichTextOptions = {
  modules: {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      ['blockquote'],

      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }], // superscript/subscript

      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme

      ['clean'], // remove formatting button
    ],
  },
}

export const SettingCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source={'type'} placeholder={'about, banner'} />
      <RichTextInput source="mainSection" multiline fullWidth options={RichTextOptions} />
      <RichTextInput source="secondarySection" multiline fullWidth options={RichTextOptions} />

      <Typography variant={'h5'}>"banner" type only</Typography>
      <TextInput source={'title'} label="Title" />
      <TextInput source={'description'} fullWidth />
      <CloudinaryFileInput source={'img.url'} />
      <TextInput source={'img.alt'} label="Image alt text" />
      <DateInput source={'displayTime.startsAt'} label="Display Time: form" />
      <DateInput source={'displayTime.endsAt'} label="Display Time: to" />
    </SimpleForm>
  </Create>
)

export const SettingEdit = (props) => (
  <Edit {...props}>
    <TabbedForm redirect={false} toolbar={<Toolbar />}>
      <FormTab label="summary">
        <TextField source="_id" label="Id" />
        <TextInput source={'type'} placeholder={'about, banner'} />
        <RichTextInput source="mainSection" multiline fullWidth options={RichTextOptions} />
        <RichTextInput source="secondarySection" multiline fullWidth options={RichTextOptions} />
        <Typography variant={'h5'}>"banner" type only</Typography>
        <TextInput source={'title'} label="Title" />
        <TextInput source={'description'} fullWidth />
        <CloudinaryFileInput source={'img.url'} editable />
        <TextInput source={'img.alt'} label="Image alt text" />
        <DateInput source={'displayTime.startsAt'} label="Display Time: form" />
        <DateInput source={'displayTime.endsAt'} label="Display Time: to" />
        <DateField source="createdAt" label="Created at" showTime />
        <DateField source="updatedAt" label="Updated at" showTime />
      </FormTab>
    </TabbedForm>
  </Edit>
)

export const SettingList = (props) => (
  <List {...props} sort={{ field: 'createdAt', order: 'DESC' }} perPage={25} filters={<Filters />}>
    <Datagrid>
      <TextField source="type" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
      <EditButton />
    </Datagrid>
  </List>
)
