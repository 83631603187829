import { Datagrid, DateField, EditButton, List, ReferenceField, TextField } from 'react-admin'
import PriceField from '../../elements/PriceField'
import React from 'react'
import { InvoiceFilters } from './index'
import InvoiceListActions from './Actions'

export const InvoiceList = (props) => (
  <List
    {...props}
    actions={<InvoiceListActions />}
    sort={{ field: 'createdAt', order: 'DESC' }}
    perPage={25}
    filters={<InvoiceFilters showBidder showSeller />}
  >
    <Datagrid>
      <TextField source={'shortId'} label={'Number'} />
      <ReferenceField source="bidderId" reference="bidders" label="Bidder">
        <TextField source="username" />
      </ReferenceField>
      <ReferenceField source="sellerId" reference="sellers">
        <TextField source="username" />
      </ReferenceField>
      <TextField source="status" />
      <PriceField source="summary.bidderTotal" label="Total price" labelOnValue={false} />
      <DateField source="paymentDate" label="Pay at" showTime />
      <DateField source="createdAt" label="Created at" showTime />
      <DateField source="updatedAt" label="Updated at" showTime />
      <EditButton />
    </Datagrid>
  </List>
)
