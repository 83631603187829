import { AutocompleteInput, Create, NumberInput, ReferenceInput, required, SimpleForm } from 'react-admin'
import React from 'react'

export const MaxBidCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <NumberInput
        min={0}
        step={0.01}
        format={(value) => Number(value) / 100}
        parse={(value) => value * 100}
        source="value"
        validate={required()}
      />
      <ReferenceInput
        source="bidderId"
        reference="bidders"
        sort={{
          field: 'username',
          order: 'ASC',
        }}
        filterToQuery={(searchText) => {
          return (
            searchText && {
              'username[$regex]': searchText,
              'username[$options]': 'i',
            }
          )
        }}
        allowEmpty
        validate={required()}
      >
        <AutocompleteInput optionText="username" emptyText="clear search" />
      </ReferenceInput>
      <ReferenceInput
        source="itemId"
        reference="items"
        sort={{
          field: 'name',
          order: 'ASC',
        }}
        filterToQuery={(searchText) => {
          return (
            searchText && {
              'name[$regex]': searchText,
              'name[$options]': 'i',
            }
          )
        }}
        allowEmpty
        validate={required()}
      >
        <AutocompleteInput optionText="name" emptyText="clear search" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
)

export default MaxBidCreate
