import {
  BooleanField,
  BooleanInput,
  DateField,
  FormTab,
  ReferenceManyField,
  required,
  TextField,
  TextInput,
} from 'react-admin'
import CloudinaryFileInput from '../../../elements/CloudinaryFileInput'
import React from 'react'
import { Typography } from '@material-ui/core'
import _ from 'lodash'

const NestedField = (props) => {
  const data = props.data?.[props.ids?.[0]]
  return <span>{_.get(data, props.source)}</span>
}

const SummaryTab = ({ bidder, ...props }) => {
  return (
    <FormTab {...props} label="summary">
      <TextField source="_id" label="Id" />
      <TextInput source="username" label="Username" validate={required()} />
      <TextInput source="taxNumber" label="Tax number" />
      <CloudinaryFileInput source="taxNumberUrl" title={'Tax Document'} editable />
      <TextInput source="email" label="Email" type="email" validate={required()} />
      <BooleanInput source="medicalLicence" label="Medical Licence" disabled={!bidder?.medicalLicenceRequired} />
      <CloudinaryFileInput
        source="medicalLicenceDocumentUrl"
        title={'Medical Licence Document'}
        editable
        disabled={!bidder?.medicalLicenceRequired}
      />
      <BooleanField label="Is Verified" source="isVerified" />
      <TextField source="verifyShortToken" />
      <BooleanInput source="isAllowedToUse" />
      <TextInput source="banReason" defaultValue={'Account blocked due to unpaid invoices.'} fullWidth />
      <DateField source="createdAt" label="Created at" showTime />
      <DateField source="updatedAt" label="Updated at" showTime />
      <br />
      <br />
      <Typography variant={'h5'}>Bidder info</Typography>
      <ReferenceManyField fullWidth reference="stripe/customers" target="bidderId" perPage={10} label="Name">
        <NestedField source="name" />
      </ReferenceManyField>
      <TextField source="email" />
      <ReferenceManyField
        fullWidth
        reference="stripe/customers"
        target="bidderId"
        perPage={10}
        sort={{
          field: 'createdAt',
          order: 'DESC',
        }}
        label="City"
      >
        <NestedField source="address.city" />
      </ReferenceManyField>
      <ReferenceManyField
        fullWidth
        reference="stripe/customers"
        target="bidderId"
        perPage={10}
        sort={{
          field: 'createdAt',
          order: 'DESC',
        }}
        label="Address 1"
      >
        <NestedField source="address.line1" />
      </ReferenceManyField>
      <ReferenceManyField
        fullWidth
        reference="stripe/customers"
        target="bidderId"
        perPage={10}
        sort={{
          field: 'createdAt',
          order: 'DESC',
        }}
        label="Address 2"
      >
        <NestedField source="address.line2" />
      </ReferenceManyField>
      <ReferenceManyField
        fullWidth
        reference="stripe/customers"
        target="bidderId"
        perPage={10}
        sort={{
          field: 'createdAt',
          order: 'DESC',
        }}
        label="Zip"
      >
        <NestedField source="address.postal_code" />
      </ReferenceManyField>
      <ReferenceManyField
        fullWidth
        reference="stripe/customers"
        target="bidderId"
        perPage={10}
        sort={{
          field: 'createdAt',
          order: 'DESC',
        }}
        label="Country"
      >
        <NestedField source="address.country" />
      </ReferenceManyField>
      <ReferenceManyField
        fullWidth
        reference="stripe/customers"
        target="bidderId"
        perPage={10}
        sort={{
          field: 'createdAt',
          order: 'DESC',
        }}
        label="State"
      >
        <NestedField source="address.state" />
      </ReferenceManyField>
      <ReferenceManyField
        fullWidth
        reference="stripe/customers"
        target="bidderId"
        perPage={10}
        sort={{
          field: 'createdAt',
          order: 'DESC',
        }}
        label="Phone"
      >
        <NestedField source="phone" />
      </ReferenceManyField>
      <TextInput source="userSource" label="User Source" />
    </FormTab>
  )
}

export default SummaryTab
