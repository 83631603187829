import { useCallback, useState } from 'react'
import { useCreate, useNotify, useRefresh, useUpdate } from 'react-admin'

const usePayments = (invoiceData, setLoading) => {
  const notify = useNotify()
  const refresh = useRefresh()
  const [paymentType, setPaymentType] = useState('')
  const [value, setValue] = useState()
  const [createPaymentIntend] = useCreate('stripe/payment-intents')

  const [pathInvoice] = useUpdate(
    'invoices',
    invoiceData._id,
    {
      paymentType: paymentType,
      paymentAmount: value * 100 + (invoiceData.paymentAmount || 0),
    },
    invoiceData,
  )
  const [refreshInvoice] = useUpdate(
    'invoices',
    invoiceData._id,
    {
      updatedAt: new Date(),
    },
    invoiceData,
  )

  const handlePaymentTypeChange = useCallback((event) => {
    setPaymentType(event.target.value)
  }, [])

  const handleValueChange = useCallback((event) => {
    setValue(event.target.value)
  }, [])

  const makePayment = useCallback(async () => {
    if (!invoiceData._id) return
    setLoading(true)

    switch (paymentType) {
      case 'card': {
        try {
          await createPaymentIntend(
            {
              payload: {
                data: {
                  userId: invoiceData.bidderId,
                  invoiceId: invoiceData._id,
                  amount: value * 100,
                },
              },
            },
            {
              onSuccess: async () => {
                await refreshInvoice()
                await refresh()
                notify('Payment Created', 'success')
              },
            },
          )
          setValue(0)
          // setTimeout(async () => {
          //
          // }, 1000)
        } catch (e) {
          notify(`Payment Failed: ${e}`, 'error')
        }
        break
      }
      case 'wire':
      case 'cash': {
        await pathInvoice()
        notify('Payment Created', 'success')
        setValue(0)
        break
      }
      default:
        break
    }
    setLoading(false)
  }, [invoiceData, createPaymentIntend, pathInvoice])

  return {
    handleValueChange,
    handlePaymentTypeChange,
    makePayment,
    paymentType,
    value,
  }
}

export default usePayments
