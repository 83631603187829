import React, { useEffect, useState } from 'react'
import { Field } from 'react-final-form'
import InputList from './InputList'

const MultipleImageInput = (props) => {
  const [widget, setWidget] = useState({})
  const [values, setValues] = useState([])

  useEffect(() => {
    const widget = window.cloudinary.createUploadWidget(
      {
        cloudName: process.env.REACT_APP_CLOUD_NAME,
        uploadPreset: process.env.REACT_APP_UPLOAD_PRESET,
        fieldName: props.source,
        multiple: true,
      },
      (error, result) => {
        if (result && result.event === 'queues-end') {
          const files = result.info.files.map((file) => {
            if (!file.uploadInfo) return
            return {
              params: '',
              url: `https://res.cloudinary.com/${process.env.REACT_APP_CLOUD_NAME}/image/upload/${file.uploadInfo.path}`,
              publicId: file.uploadInfo.public_id,
              isMain: false,
              isCode: false,
              lotId: '',
            }
          })
          setValues(files)
        }
        if (error) {
          console.log(error)
        }
      },
    )
    setWidget(widget)
  }, [props.source])

  return (
    <Field name={props.source}>
      {(formProps) => {
        return <InputList formProps={formProps} widget={widget} values={values} rest={props} />
      }}
    </Field>
  )
}

export default MultipleImageInput
