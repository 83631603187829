import {
  ArrayInput,
  BooleanInput,
  FormDataConsumer,
  FormTab,
  NumberInput,
  required,
  TextField,
  TextInput,
  useGetOne,
} from 'react-admin'
import PriceField from '../../elements/PriceField'
import Typography from '@material-ui/core/Typography'
import { format, parseISO } from 'date-fns'
import CustomFormIterator from '../../elements/CustomFormInterator/CustomFormIterator'
import CustomTextField from '../../elements/CustomTextField'
import SummaryTable from './SumaryTable'
import React from 'react'
import LinkToClient from './LinkToClient'
import RichTextInput from 'ra-input-rich-text'

const ItemTab = (props) => {
  const { data: invoiceData } = useGetOne('invoices', props.id)
  return (
    <FormTab {...props} label="Items">
      <TextField source="_id" />
      <TextField source="status" />
      <TextField source="shortId" label="Invoice number" />
      <TextField source="bidder.taxNumber" label="Tax Number" />
      <PriceField source="paymentAmount" label="Payment amount" />
      <BooleanInput source="isRefund" label="Refund manually" />
      <BooleanInput source="isPickedUp" label="Picked Up" />
      <RichTextInput source="info" label="Info" />
      {invoiceData.paymentType && (
        <>
          <Typography component="span" variant="caption" color="textSecondary">
            Invoice paid
          </Typography>
          <div style={{ padding: '8px 0px 8px 0px' }}>
            <Typography component="span" variant="body" {...props}>
              {invoiceData.paymentDate ? format(parseISO(invoiceData.paymentDate.toString()), 'MM.dd.yyyy HH:mm') : ''}
            </Typography>
          </div>
          <Typography component="span" variant="caption" color="textSecondary">
            Payment type
          </Typography>
          <div style={{ padding: '8px 0px 8px 0px' }}>
            <Typography component="span" variant="body" {...props}>
              {invoiceData.paymentType}
            </Typography>
          </div>
        </>
      )}
      <br />
      <Typography variant="h6">Items</Typography>
      <ArrayInput source="items" label={false}>
        <CustomFormIterator>
          <FormDataConsumer>
            {({ scopedFormData, getSource }) => {
              return scopedFormData?._id ? (
                <LinkToClient record={scopedFormData}>
                  <CustomTextField
                    source={'name'}
                    record={scopedFormData}
                    color={scopedFormData.bidderTotal < 0 ? 'error' : undefined}
                  />
                </LinkToClient>
              ) : (
                <TextInput
                  source={getSource('name')}
                  label={
                    <Typography component={'span'} variant="body2">
                      Name
                    </Typography>
                  }
                  validate={required()}
                />
              )
            }}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ scopedFormData, getSource }) => {
              return scopedFormData?._id ? (
                <CustomTextField
                  source={'lotId'}
                  record={scopedFormData}
                  color={scopedFormData.bidderTotal < 0 ? 'error' : undefined}
                />
              ) : (
                <TextInput
                  source={getSource('lotId')}
                  label={
                    <Typography component={'span'} variant="body2">
                      Lot #
                    </Typography>
                  }
                  validate={required()}
                />
              )
            }}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ scopedFormData, getSource }) => {
              return scopedFormData?._id ? (
                <CustomTextField
                  source={'shortId'}
                  record={scopedFormData}
                  color={scopedFormData.bidderTotal < 0 ? 'error' : undefined}
                />
              ) : (
                <TextInput
                  source={getSource('shortId')}
                  label={
                    <Typography component={'span'} variant="body2">
                      Short Id
                    </Typography>
                  }
                />
              )
            }}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ scopedFormData, getSource }) => {
              return scopedFormData?._id ? (
                <PriceField
                  source={'sales'}
                  record={scopedFormData}
                  color={scopedFormData.bidderTotal < 0 ? 'error' : undefined}
                />
              ) : (
                <NumberInput
                  format={(value) => value / 100}
                  parse={(value) => Number((value * 100).toFixed(0))}
                  source={getSource('sales')}
                  label={
                    <Typography component={'span'} variant="body2">
                      Sales [$]
                    </Typography>
                  }
                  validate={required()}
                  step={0.01}
                />
              )
            }}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ scopedFormData, getSource }) => {
              return scopedFormData?._id ? (
                <PriceField
                  source={'net'}
                  record={scopedFormData}
                  color={scopedFormData.bidderTotal < 0 ? 'error' : undefined}
                />
              ) : null
            }}
          </FormDataConsumer>

          <FormDataConsumer>
            {({ scopedFormData, getSource }) => {
              return scopedFormData?._id ? (
                <PriceField
                  source={'buyerPremium.value'}
                  record={scopedFormData}
                  color={scopedFormData.bidderTotal < 0 ? 'error' : undefined}
                />
              ) : (
                <NumberInput
                  format={(value) => value / 100}
                  parse={(value) => Number((value * 100).toFixed(0))}
                  source={getSource('buyerPremium.value')}
                  label={
                    <Typography component={'span'} variant="body2">
                      Buyer's premium [$]
                    </Typography>
                  }
                  validate={required()}
                  step={0.01}
                />
              )
            }}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ scopedFormData, getSource }) => {
              return scopedFormData?._id ? (
                <PriceField
                  source={'tax.value'}
                  record={scopedFormData}
                  color={scopedFormData.bidderTotal < 0 ? 'error' : undefined}
                />
              ) : (
                <NumberInput
                  format={(value) => {
                    if (Number(value)) return value / 100
                    return value
                  }}
                  parse={(value) => {
                    if (Number(value)) return Number((value * 100).toFixed(0))
                    return value
                  }}
                  source={getSource('tax.value')}
                  label={
                    <Typography component={'span'} variant="body2">
                      Tax [$]
                    </Typography>
                  }
                  validate={required()}
                  step={0.01}
                />
              )
            }}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ scopedFormData }) => {
              return scopedFormData?._id ? (
                <PriceField
                  source={'commission.value'}
                  record={scopedFormData}
                  color={scopedFormData.bidderTotal < 0 ? 'error' : undefined}
                />
              ) : null
            }}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ scopedFormData }) => {
              return scopedFormData?._id ? (
                <PriceField
                  source={'bidderTotal'}
                  record={scopedFormData}
                  color={scopedFormData.bidderTotal < 0 ? 'error' : undefined}
                />
              ) : null
            }}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ scopedFormData, getSource }) => {
              return scopedFormData?._id ? (
                <BooleanInput label="" source={getSource('nonTaxable')} record={scopedFormData} />
              ) : (
                <BooleanInput label="" source={getSource('nonTaxable')} />
              )
            }}
          </FormDataConsumer>
        </CustomFormIterator>
      </ArrayInput>
      <br />
      <Typography variant="h6">Summary</Typography>
      <br />
      <SummaryTable props={props} />
    </FormTab>
  )
}
export default ItemTab
