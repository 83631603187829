import React, { useEffect, useState } from 'react'
import { Field } from 'react-final-form'
import { Typography, IconButton } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Delete'
import styled from 'styled-components'
import get from 'lodash/get'

const Text = styled(Typography)`
  background: rgba(0, 0, 0, 0.04);
  cursor: pointer;
  width: 236px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgba(0, 0, 0, 0.54);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  margin: 10px 0 !important;
  padding: 10px 10px;
`

const DisabledText = styled(Text)`
  cursor: not-allowed;
`

const Input = ({ widget, formProps, props, value }) => {
  useEffect(() => {
    if (value) {
      formProps.input.onChange(value)
    }
  }, [value])
  return (
    <div>
      {formProps.input.value ? (
        <>
          <Typography component="span" variant="caption" color="textSecondary">
            File
          </Typography>
          <br />
          <a href={get(props.record, props.source)} target={'_blank'}>
            {props.title || 'Document'}
          </a>
          {!props.editable ? null : (
            <IconButton onClick={() => formProps.input.onChange(undefined)}>
              <RemoveIcon />
            </IconButton>
          )}
        </>
      ) : !props.disabled ? (
        <Text onClick={widget.open}>
          {`Upload File ${props.required ? '*' : ''}`}
          <AddIcon color="inherit" />
        </Text>
      ) : (
        <DisabledText>
          {`Upload File ${props.required ? '*' : ''}`}
          <AddIcon color="inherit" />
        </DisabledText>
      )}
    </div>
  )
}

const CloudinaryFileInput = (props) => {
  const [widget, setWidget] = useState({})
  const [value, setValue] = useState(undefined)

  useEffect(() => {
    const widget = window.cloudinary.createUploadWidget(
      {
        cloudName: process.env.REACT_APP_CLOUD_NAME,
        uploadPreset: process.env.REACT_APP_UPLOAD_PRESET,
        fieldName: props.source,
        cropping: false,
      },
      (error, result) => {
        if (result && result.event === 'success') {
          setValue(result.info.url)
        }
        if (error) {
          console.log(error)
        }
      },
    )
    setWidget(widget)
  }, [])

  return (
    <Field name={props.source}>
      {(formProps) => {
        return <Input formProps={formProps} props={props} widget={widget} value={value} />
      }}
    </Field>
  )
}

export default CloudinaryFileInput
