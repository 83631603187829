import React from 'react'
import { List, Datagrid, TextField, ReferenceField, Filter, DateField } from 'react-admin'
import DateFilters from '../../elements/DateFilters'

export const Filters = (props) => <Filter {...props}>{DateFilters}</Filter>

export const StripePaymentMethodsList = (props) => (
  <List {...props} sort={{ field: 'createdAt', order: 'DESC' }} perPage={25} filters={<Filters />}>
    <Datagrid>
      <ReferenceField source="bidderId" reference="bidders" label="Bidder">
        <TextField source="username" />
      </ReferenceField>
      <TextField source="id" label="Stripe Id" />
      <TextField source="_id" label="Id" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
  </List>
)
