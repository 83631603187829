import { Typography } from '@material-ui/core'
import get from 'lodash/get'
import React from 'react'

const CustomTextField = ({ source, record, label, labelOnValue = true, ...props }) => {
  const value = get(record, source)

  return (
    <>
      <Typography component="span" variant="caption" color="textSecondary">
        {labelOnValue ? label : null}
      </Typography>
      <div style={{ padding: '8px 0px 4px 0px' }}>
        <Typography component="span" variant="body2" {...props}>
          {value}
        </Typography>
      </div>
    </>
  )
}

export default CustomTextField
