import React, { useCallback } from 'react'
import { SaveButton, useNotify, useRefresh } from 'react-admin'
import feathersClient from '../../../client/feathersClient'

const CustomSaveButton = (props) => {
  const notify = useNotify()
  const refresh = useRefresh()
  const { basePath } = props
  const handleSave = useCallback(
    async (values) => {
      try {
        await feathersClient.service('items').patch(values.id, {
          isManual: true,
          status: 'sellingManually',
          currentBidderId: values.currentBidderId,
          currentValue: values.initialPrice,
          initialPrice: values.initialPrice,
        })
        notify('Item changed', 'info')
        refresh()
      } catch (e) {
        notify(e)
      }
      props.onFinish()
    },
    [notify, basePath],
  )
  return <SaveButton {...props} onSave={handleSave} label="Sell" />
}

export default CustomSaveButton
