import {
  Datagrid,
  DateField,
  EditButton,
  FormTab,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  TextField,
} from 'react-admin'
import PriceField from '../../../elements/PriceField'
import React from 'react'

const ItemsTab = (props) => {
  return (
    <FormTab {...props} label="items" path="items">
      <ReferenceManyField
        addLabel={false}
        fullWidth
        reference="items"
        target="currentBidderId"
        filter={{ currentBidderId: props.record?._id }}
        pagination={<Pagination />}
      >
        <Datagrid>
          <TextField source="name" label="Title" />
          <ReferenceField source="categoryId" reference="categories" label="Category">
            <TextField source="name" />
          </ReferenceField>
          <TextField source="shortId" />
          <TextField source="lotId" />
          <PriceField source="initialPrice" />
          <TextField source="status" label="Status" />
          <DateField source="endDate" showTime />
          <EditButton />
        </Datagrid>
      </ReferenceManyField>
    </FormTab>
  )
}

export default ItemsTab
