import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Filter,
  Create,
  SimpleForm,
  TextInput,
  required,
  Edit,
  TabbedForm,
  FormTab,
  EditButton,
} from 'react-admin'
import DateFilters from '../../elements/DateFilters'
import HeaderTextField from '../../elements/HeaderTextField'
import Toolbar from '../../elements/Toolbar'

export const Filters = (props) => <Filter {...props}>{DateFilters}</Filter>

export const LocationCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="city" validate={required()} />
      <TextInput source="state" validate={required()} />
    </SimpleForm>
  </Create>
)

export const LocationEdit = (props) => (
  <Edit {...props} title={<HeaderTextField source="city" label="Location:" />}>
    <TabbedForm redirect={false} toolbar={<Toolbar />}>
      <FormTab label="summary">
        <TextField source="_id" label="Id" />
        <TextInput source="city" validate={required()} />
        <TextInput source="state" validate={required()} />
        <DateField source="createdAt" label="Created at" showTime />
        <DateField source="updatedAt" label="Updated at" showTime />
      </FormTab>
    </TabbedForm>
  </Edit>
)

export const LocationList = (props) => (
  <List {...props} sort={{ field: 'createdAt', order: 'DESC' }} perPage={25} filters={<Filters />}>
    <Datagrid>
      <TextField source="city" />
      <TextField source="state" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
      <EditButton />
    </Datagrid>
  </List>
)
