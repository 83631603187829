import { login } from './login'
import { checkError } from './checkError'
import { checkAuth } from './checkAuth'
import { logout } from './logout'
import { getIdentity } from './getIdentity'
import { getPermissions } from './getPermissions'

const authProvider = (client) => ({
  // authentication
  login: login(client),
  checkError: checkError(client),
  checkAuth: checkAuth(client),
  logout: logout(client),
  getIdentity: getIdentity(client),
  // authorization
  getPermissions: getPermissions(client),
})

export default authProvider
