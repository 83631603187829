import React from 'react'
import { List, Datagrid, TextField, ReferenceField, DateField, Filter } from 'react-admin'
import DateFilters from '../../elements/DateFilters'

export const Filters = (props) => <Filter {...props}>{DateFilters}</Filter>

export const SessionList = (props) => (
  <List {...props} sort={{ field: 'createdAt', order: 'DESC' }} perPage={25} filters={<Filters />}>
    <Datagrid>
      <ReferenceField source="userId" reference="users" label="User">
        <TextField source="username" />
      </ReferenceField>
      <TextField source="_id" label="Id" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
  </List>
)
