import { getIdentity } from './getIdentity'

export const getPermissions = (client) => async (params) => {
  const user = await getIdentity(client)()
  switch (user.type) {
    case 'admin':
      return ['all']
    case 'financialReporter':
      return ['reports']
  }
}
