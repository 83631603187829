import React from 'react'
import { cloneElement, useCallback } from 'react'
import {
  useListContext,
  TopToolbar,
  CreateButton,
  Button,
  sanitizeListRestProps,
  useNotify,
  useRefresh,
} from 'react-admin'
import app from '../../client/feathersClient'

const InvoiceListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props
  const { currentSort, resource, displayedFilters, filterValues, basePath, hasCreate, showFilter, total } =
    useListContext()
  const notify = useNotify()
  const refresh = useRefresh()

  const generateInvoices = useCallback(async (sellManually = false) => {
    try {
      notify('Generating invoices...', 'info', {}, false, 2000)
      await app.service('generate').create(
        { sellManually },
        {
          headers: { 'x-service-method': 'invoices' },
        },
      )
      refresh()
      notify('Generated!', 'info', {}, false, 3000)
    } catch (e) {
      console.log('e ===', e)
      notify('Something went wrong!', 'warning', {}, false, 3000)
    }
  }, [])

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {hasCreate && <CreateButton basePath={basePath} />}

      {/* Add your custom actions */}

      <Button label="Invoice" onClick={() => generateInvoices()} />
      <Button label="Invoice manual" onClick={() => generateInvoices(true)} />
    </TopToolbar>
  )
}

export default InvoiceListActions
