const _config = [
  {
    field: 'createdAfter',
    mongoFiled: 'createdAt',
    parse: (v) => ({
      $gt: v,
    }),
    merge: true,
  },
  {
    field: 'createdBefore',
    mongoFiled: 'createdAt',
    parse: (v) => ({
      $lt: v,
    }),
    merge: true,
  },
  {
    field: 'updatedAfter',
    mongoFiled: 'updatedAt',
    parse: (v) => ({
      $gt: v,
    }),
    merge: true,
  },
  {
    field: 'updatedBefore',
    mongoFiled: 'updatedAt',
    parse: (v) => ({
      $lt: v,
    }),
    merge: true,
  },
  {
    field: 'endedAfter',
    mongoFiled: 'endDate',
    parse: (v) => ({
      $gt: v,
    }),
    merge: true,
  },
  {
    field: 'endedBefore',
    mongoFiled: 'endDate',
    parse: (v) => ({
      $lt: v,
    }),
    merge: true,
  },
  {
    field: 'startedAfter',
    mongoFiled: 'startDate',
    parse: (v) => ({
      $gt: v,
    }),
    merge: true,
  },
  {
    field: 'startedBefore',
    mongoFiled: 'startDate',
    parse: (v) => ({
      $lt: v,
    }),
    merge: true,
  },
  {
    field: 'payAfter',
    mongoFiled: 'paymentDate',
    parse: (v) => ({
      $gt: v,
    }),
    merge: true,
  },
  {
    field: 'payBefore',
    mongoFiled: 'paymentDate',
    parse: (v) => ({
      $lt: v,
    }),
    merge: true,
  },
  {
    partial: true,
    field: '.$regex',
    parse: (v) => ({
      $regex: v,
      $options: 'i',
    }),
  },
]

export default function mapQuery(config = _config) {
  return async (context) => {
    if (context.params.query) {
      config.forEach(({ field, mongoFiled, parse, partial, ...option }) => {
        if (partial) {
          Object.keys(context.params.query).map((key) => {
            if (key.includes(field)) {
              const mongoKey = key.replace(field, '')
              context.params.query[mongoKey] = parse(context.params.query[key])
              delete context.params.query[key]
              return context
            }
          })
        }

        if (context.params.query[field]) {
          if (option.merge) {
            context.params.query[mongoFiled] = {
              ...context.params.query[mongoFiled],
              ...parse(context.params.query[field]),
            }
          } else {
            context.params.query[mongoFiled] = parse(context.params.query[field])
          }

          delete context.params.query[field]
        }
      })
    }
  }
}
