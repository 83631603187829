import React, { useMemo, useState } from 'react'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import DateFnsUtils from '@date-io/date-fns'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { format } from 'date-fns'
import StatusBadge from '../../../elements/StatusBadge'
import useSellers from '../useSellers'
import useReports from '../useReports'
import useToFile from '../useToFile'

const PreliminaryReports = () => {
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [sellerId, setSellerId] = useState(undefined)

  const sellers = useSellers()

  const query = useMemo(() => {
    return {
      type: 'preliminary',
      startDate,
      endDate,
      sellerId,
    }
  }, [startDate, endDate, sellerId])
  const { data, totals } = useReports(query)

  const generateReport = useToFile({
    data,
    type: 'preliminary',
    totals,
    filename: `preliminaryReport(${format(startDate, 'MM.dd.yyyy')}-${format(endDate, 'MM.dd.yyyy')})`,
  })

  return (
    <Container maxWidth={false}>
      <Grid container spacing={1} direction={'column'}>
        <Grid
          item
          xs={12}
          align="center"
          style={{
            marginTop: 20,
          }}
        >
          <Typography variant="h5">Preliminary report</Typography>
        </Grid>
        <Grid container item spacing={2} xs={12} direction={'row'}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container item xs={12} sm={6} md={3} direction="column">
              <Typography variant={'caption'} style={{ marginRight: '16px' }}>
                Start date
              </Typography>
              <DatePicker value={startDate} onChange={setStartDate} />
            </Grid>
            <Grid container item xs={12} sm={6} md={3} direction="column">
              <Typography variant={'caption'} style={{ marginRight: '16px' }}>
                End date
              </Typography>
              <DatePicker value={endDate} onChange={setEndDate} />
            </Grid>
            <Grid container item xs={12} sm={6} md={3} direction="column">
              <Typography variant={'caption'} style={{ marginRight: '16px' }}>
                Seller
              </Typography>
              <Autocomplete
                options={sellers}
                getOptionLabel={(option) => option.username}
                renderInput={(params) => <TextField {...params} />}
                value={sellers.find((seller) => seller._id === sellerId)}
                onChange={(event, newValue) => {
                  setSellerId(newValue?._id)
                }}
              />
            </Grid>
            <Grid container item xs={12} sm={6} md={3} alignContent="center">
              <Button variant={'contained'} color={'primary'} onClick={generateReport}>
                Generate report XLSX
              </Button>
            </Grid>
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Invoice nr</TableCell>
                  <TableCell>Lot id</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>End date</TableCell>
                  <TableCell>Invoice status</TableCell>
                  <TableCell>Bidder</TableCell>
                  <TableCell>Item status</TableCell>
                  <TableCell>Price</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {row.invoiceNr}
                    </TableCell>
                    <TableCell>{row.lotId}</TableCell>
                    <TableCell>{row.title}</TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">{row.endDate?.split(' ')[0]}</Typography>
                      <Typography variant="caption">
                        {row.endDate?.split(' ')[1]} {row.endDate?.split(' ')[2]}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <StatusBadge status={row.invoiceStatus}>
                        <Typography variant={'body2'}>{row.invoiceStatus}</Typography>
                      </StatusBadge>
                    </TableCell>
                    <TableCell>
                      <Typography variant={'body1'}>{row.bidder}</Typography>
                      <Typography variant={'subtitle2'}>{row.bidderEmail}</Typography>
                      <Typography variant={'caption'}>{row.bidderPhone}</Typography>
                    </TableCell>
                    <TableCell>
                      <StatusBadge status={row.itemStatus}>
                        <Typography variant={'body2'}>{row.itemStatus}</Typography>
                      </StatusBadge>
                    </TableCell>
                    <TableCell>{row.price}</TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell>Total:</TableCell>
                  <TableCell>{totals.total}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Container>
  )
}

export default PreliminaryReports
