import {
  AutocompleteInput,
  BooleanInput,
  Datagrid,
  DateField,
  EditButton,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  TextField,
  TextInput,
} from 'react-admin'
import ShowNestedRouteButton from '../../elements/ShowNestedRouteButton'
import React from 'react'
import DateFilters from '../../elements/DateFilters'

const Filters = (props) => (
  <Filter {...props}>
    <TextInput label="Username" source="username.$regex" />
    <TextInput label="Email" source="email.$regex" />
    <ReferenceInput
      label={'Name'}
      source="customerId"
      reference="stripe/customers"
      filterToQuery={(searchText) => {
        return (
          searchText && {
            'name[$regex]': searchText,
            'name[$options]': 'i',
          }
        )
      }}
      allowEmpty
    >
      <AutocompleteInput allowEmpty optionText="name" emptyText="clear search" />
    </ReferenceInput>
    {DateFilters}
    <BooleanInput defaultValue={true} source="isAllowedToUse" />
  </Filter>
)

const BidderList = (props) => {
  return (
    <List {...props} sort={{ field: 'createdAt', order: 'DESC' }} perPage={25} filters={<Filters />}>
      <Datagrid>
        <TextField source="username" label="Username" />
        <ReferenceField source="customerId" reference="stripe/customers" label="Name">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="email" label="Email" />
        <DateField source="createdAt" showTime />
        <DateField source="updatedAt" showTime />
        <ShowNestedRouteButton mainPath="bidders" nestedPath="1">
          Show invoices
        </ShowNestedRouteButton>
        <ShowNestedRouteButton mainPath="bidders" nestedPath="items">
          Show items
        </ShowNestedRouteButton>
        <EditButton />
      </Datagrid>
    </List>
  )
}

export default BidderList
