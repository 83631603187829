import { useEffect, useState } from 'react'
import app from '../../client/feathersClient'

const useReports = () => {
  const [sellers, setSellers] = useState([])
  useEffect(() => {
    const getData = async () => {
      try {
        const res = await app.service('sellers').find({ query: { $limit: 1000 } })
        setSellers(res.data)
      } catch (e) {
        console.log('error on getting sellers ===', e)
      }
    }
    getData()
  }, [])
  return sellers
}

export default useReports
