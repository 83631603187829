import { Datagrid, DateField, EditButton, List, TextField, ReferenceField } from 'react-admin'
import React from 'react'
import { Filters } from '../faqs'
import { isPast } from 'date-fns'
import ShowNestedRouteButton from '../../elements/ShowNestedRouteButton'

const DisabledEditButton = (props) => {
  const date = new Date(props.record.endDate)
  return <EditButton {...props} disabled={isPast(date)} />
}

export const AuctionList = (props) => (
  <List {...props} sort={{ field: 'createdAt', order: 'DESC' }} perPage={25} filters={<Filters />}>
    <Datagrid>
      <TextField source="_id" label="Id" />
      <ReferenceField source="sellerId" reference="sellers" label="Seller">
        <TextField source="username" />
      </ReferenceField>
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
      <ShowNestedRouteButton mainPath="auctions" nestedPath="items">
        Show items
      </ShowNestedRouteButton>
      <DisabledEditButton />
    </Datagrid>
  </List>
)
