import React, { useState, useEffect } from 'react'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import { Loading, Error, useGetIdentity } from 'react-admin'
import app from '../client/feathersClient'

const Dashboard = (props) => {
  const { identity } = useGetIdentity()
  const [stats, setStats] = useState()
  const [bidderSources, setBidderSources] = useState()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()

  useEffect(() => {
    const getStats = async () => {
      try {
        const [statsRes, sourcesRes] = await Promise.all([
          app.service('stats').find({ query: { allAuctions: true } }),
          app.service('stats').find({ query: { bidderSources: true } }),
        ])
        setStats(statsRes)
        setBidderSources(sourcesRes)
      } catch (e) {
        setError(e)
      } finally {
        setLoading(false)
      }
    }
    if (identity) getStats()
  }, [identity])

  if (loading) return <Loading />
  if (error) return <Error />
  if (!stats) return null

  return (
    <Container maxWidth={false}>
      <Grid container spacing={1} direction={'column'}>
        <Grid
          item
          xs={12}
          align="center"
          style={{
            marginTop: 20,
          }}
        >
          <Typography variant="h5">Surplus Marketplace: Dashboard</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Auctions</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card variant="outlined">
            <CardHeader
              title="Active"
              titleTypographyProps={{
                variant: 'caption',
                color: 'initial',
              }}
              subheader={stats.activeAuctions}
              subheaderTypographyProps={{
                variant: 'h5',
                color: 'initial',
              }}
            />
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card variant="outlined">
            <CardHeader
              title="Total"
              titleTypographyProps={{
                variant: 'caption',
                color: 'initial',
              }}
              subheader={stats.allAuctions}
              subheaderTypographyProps={{
                variant: 'h5',
                color: 'initial',
              }}
            />
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Bidders Sources</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          {Object.keys(bidderSources).map((source) => {
            const value = bidderSources[source]
            return (
              <Card variant="outlined">
                <CardHeader
                  title={source}
                  titleTypographyProps={{
                    variant: 'caption',
                    color: 'initial',
                  }}
                  subheader={value}
                  subheaderTypographyProps={{
                    variant: 'h5',
                    color: 'initial',
                  }}
                />
              </Card>
            )
          })}
        </Grid>
      </Grid>
    </Container>
  )
}

export default Dashboard
