import {
  AutocompleteInput,
  Datagrid,
  DateField,
  DateInput,
  DateTimeInput,
  Edit,
  EditButton,
  FormTab,
  NumberInput,
  Pagination,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  SelectInput,
  TabbedForm,
  TextField,
  TextInput,
  required,
} from 'react-admin'

import MultipleImageInput from '../../elements/MultipleImageInput'
import PriceField from '../../elements/PriceField'
import React from 'react'
import RichTextInput from 'ra-input-rich-text'
import Toolbar from '../../elements/Toolbar'
import Typography from '@material-ui/core/Typography'

export const AuctionsEdit = (props) => {
  return (
    <Edit {...props}>
      <TabbedForm redirect={false} toolbar={<Toolbar />}>
        <FormTab label="summary">
          <TextField source="_id" label="Id" />
          <ReferenceField source="sellerId" reference="sellers" label="Seller" validate={required()}>
            <TextField source="username" />
          </ReferenceField>

          <DateTimeInput source="startDate" showTime validate={required()} />
          <DateTimeInput source="endDate" showTime validate={required()} />

          <NumberInput
            source={'interval'}
            helperText={'time [s] to separate end each item'}
            defaultValue={60}
            validate={required()}
          />
          <SelectInput
            source="status"
            choices={[
              { id: 'published', name: 'published' },
              { id: 'unsold', name: 'unsold' },
              { id: 'endEarly', name: 'endEarly' },
              { id: 'internal', name: 'internal' },
              { id: 'endInternal', name: 'endInternal' },
            ]}
            validate={required()}
          />
          <Typography variant="h6">Removal Information</Typography>
          <TextInput source="removal.email" label="Email" />
          <TextInput source="removal.companyName" label="Company Name" />
          <TextInput source="removal.firstName" label="First Name" />
          <TextInput source="removal.lastName" label="Last Name" />
          <TextInput source="removal.address1" label="Address 1" />
          <TextInput source="removal.address2" label="Address 2" />
          <ReferenceInput
            source="removal.locationId"
            reference="locations"
            label="Location"
            sort={{
              field: 'city',
              order: 'ASC',
            }}
            filterToQuery={(searchText) => {
              return (
                searchText && {
                  'city[$regex]': searchText,
                  'city[$options]': 'i',
                }
              )
            }}
            allowEmpty
          >
            <AutocompleteInput
              optionText={(record) => {
                if (!record?._id) {
                  return 'clear search'
                }
                return `${record.city}, ${record.state}`
              }}
            />
          </ReferenceInput>
          <TextInput source="removal.zip" label="Zip" />
          <DateInput source="removal.date" label="Date" />
          <TextInput source="removal.phone" label="Phone" />
          <RichTextInput source="removal.description" label="Description" multiline fullWidth />
          <Typography variant="h6">Inspection Information</Typography>
          <ReferenceInput
            source="inspection.locationId"
            reference="locations"
            label="Location"
            sort={{
              field: 'city',
              order: 'ASC',
            }}
            filterToQuery={(searchText) => {
              return (
                searchText && {
                  'city[$regex]': searchText,
                  'city[$options]': 'i',
                }
              )
            }}
            allowEmpty
          >
            <AutocompleteInput
              optionText={(record) => {
                if (!record?._id) {
                  return 'clear search'
                }
                return `${record.city}, ${record.state}`
              }}
            />
          </ReferenceInput>
          <DateInput source="inspection.date" label="Date" />
          <RichTextInput source="inspection.description" label="Description" multiline fullWidth />
          <Typography variant="h6">Redistribution Information</Typography>
          <TextInput source="redistribution.email" label="Redistribution Email" />
          <RichTextInput source="redistribution.terms" label="Redistribution Terms" multiline fullWidth />
          <Typography variant="h6">Other Information</Typography>
          <NumberInput source="buyerPremium" label="Buyer's Premium [%]" />
          <RichTextInput source="termsAndConditions" label="Terms And Conditions" multiline fullWidth />
          <RichTextInput source="additionalInfo" label="Additional Info" multiline fullWidth />
        </FormTab>
        <FormTab label="images" path="images">
          <MultipleImageInput source={'pictures'} withLotId />
        </FormTab>

        <FormTab label="items" path="items">
          <ReferenceManyField
            addLabel={false}
            fullWidth
            reference="items"
            target="auctionId"
            filter={{ auctionId: props.id }}
            pagination={<Pagination />}
          >
            <Datagrid>
              <TextField source="shortId" />
              <TextField source="lotId" />
              <TextField source="pictures.length" label={'Pictures'} />
              <PriceField source="initialPrice" />
              <TextField source="status" label="Status" />
              <DateField source="endDate" showTime />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}
