import { useEffect, useState } from 'react'
import app from '../../client/feathersClient'

const useReports = (query) => {
  const [report, setReport] = useState({})
  useEffect(() => {
    const getData = async () => {
      try {
        const res = await app.service('reports').find({ query })
        setReport(res.data)
      } catch (e) {
        console.log('error on getting report data ===', e)
      }
    }
    getData()
  }, [query])
  return { data: report.data || [], totals: report.totals || [] }
}

export default useReports
