import styled from 'styled-components'

const Img = styled('img')`
  margin: 0.5rem;
  height: 250px;
  width: 800px;
  object-fit: contain;
`

export default Img
