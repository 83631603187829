import Paper from '@material-ui/core/Paper'
import { Button, Checkbox, FormControlLabel, FormGroup, Input as MUIInput, Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Img from './Img'
import changeAngle from './changeAngle'
import RotateRightIcon from '@material-ui/icons/RotateRight'
import RotateLeftIcon from '@material-ui/icons/RotateLeft'
import React from 'react'

const renderElement =
  ({ images, changeItem, isUpdateNext, removeElement, withLotId }) =>
  ({
    key, // Unique key within array of rows
    index, // Index of row within collection
    style, // Style object to be applied to row (to position it)
  }) => {
    const img = images[index]
    return (
      <Paper key={key} style={{ ...style }}>
        <Typography style={{ marginLeft: '25px', paddingTop: '25px' }}>{index + 1}</Typography>
        <Grid container spacing={2}>
          <Grid item xs direction={'column'} container alignItems={'center'}>
            <Img src={img.url} />
            <Grid item xs container justify={'center'}>
              <Button type={'button'} onClick={() => changeItem(index, changeAngle(img.url, img.params))}>
                <RotateRightIcon />
              </Button>
              <Button type={'button'} onClick={() => changeItem(index, changeAngle(img.url, img.params, false))}>
                <RotateLeftIcon />
              </Button>
            </Grid>
          </Grid>
          <Grid item xs container direction="column" spacing={2}>
            <Grid item container direction="row" alignItems={'center'} spacing={2}>
              {withLotId && (
                <>
                  <Typography style={{ marginRight: '15px' }}>{'Lot #'}</Typography>
                  <MUIInput
                    value={img.lotId}
                    placeholder={'lotId'}
                    onChange={(e) => changeItem(index, { lotId: e.target.value }, isUpdateNext)}
                  />
                </>
              )}
            </Grid>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox checked={img.isMain} onChange={(e) => changeItem(index, { isMain: e.target.checked })} />
                }
                label="set as main"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={img.isCode} onChange={(e) => changeItem(index, { isCode: e.target.checked })} />
                }
                label="set as bar code"
              />
              <div style={{ maxWidth: 240 }}>
                <MUIInput
                  value={img.imageAlt}
                  placeholder={'Image Alt'}
                  fullWidth={false}
                  onChange={(e) => changeItem(index, { imageAlt: e.target.value }, isUpdateNext)}
                />
              </div>
            </FormGroup>
            <Grid item>
              <Button onClick={() => removeElement(index, withLotId)}>Remove</Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    )
  }

export default renderElement
