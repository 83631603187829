import { Typography } from '@material-ui/core'
import get from 'lodash/get'
import React from 'react'

const HeaderTextField = ({ source, record, label, ...props }) => {
  const value = get(record, source)

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Typography component="span" variant="h6">
        {label}
      </Typography>
      <Typography component="span" variant="h6" style={{ marginLeft: '12px' }} {...props}>
        {value}
      </Typography>
    </div>
  )
}

export default HeaderTextField
