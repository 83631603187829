import { DateField, ListButton, ReferenceField, Show, Tab, TabbedShowLayout, TextField, TopToolbar } from 'react-admin'
import React from 'react'
import PriceField from '../../elements/PriceField'

const Actions = ({ basePath }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
  </TopToolbar>
)

export const BidShow = (props) => (
  <Show {...props} undoable={false} actions={<Actions />}>
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="_id" label="Id" />
        <PriceField source="value" label="Value" />
        <ReferenceField source="bidderId" reference="bidders">
          <TextField source="username" />
        </ReferenceField>
        <ReferenceField source="itemId" reference="items">
          <TextField source="name" />
        </ReferenceField>
        <DateField source="createdAt" label="Created at" showTime />
        <DateField source="updatedAt" label="Updated at" showTime />
      </Tab>
    </TabbedShowLayout>
  </Show>
)

export default BidShow
