import { Datagrid, DateField, FormTab, Pagination, ReferenceManyField, TextField } from 'react-admin'
import ShowExternalButton from '../../../elements/ShowExternalButtons'
import React from 'react'

const SessionsTab = (props) => {
  return (
    <FormTab {...props} label="Sessions" path="sessions">
      <ReferenceManyField
        addLabel={false}
        fullWidth
        reference="sessions"
        target="userId"
        perPage={10}
        pagination={<Pagination />}
        sort={{
          field: 'createdAt',
          order: 'DESC',
        }}
      >
        <Datagrid>
          <TextField source="_id" />
          <DateField source="createdAt" showTime />
          <DateField source="updatedAt" showTime />
          <ShowExternalButton reference="_id" service="sessions">
            Show details
          </ShowExternalButton>
        </Datagrid>
      </ReferenceManyField>
    </FormTab>
  )
}

export default SessionsTab
