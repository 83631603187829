import { storageKey } from './constants'

const logoutOnForbidden = false

export const checkError = (client) => (error) => {
  const { code } = error
  if (code === 401 || (logoutOnForbidden && code === 403)) {
    localStorage.removeItem(storageKey)
    return Promise.reject()
  }
  return Promise.resolve()
}
