import mapQuery from './hooks/mapQuery'
import removeFieldFromData from './hooks/removeFieldFromData'

export default {
  before: {
    all: [],
    find: [mapQuery()],
    get: [],
    create: [removeFieldFromData('_id')],
    update: [],
    patch: [],
    remove: [],
  },

  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },

  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
}
