import { FormTab, ReferenceField, TextField, TextInput } from 'react-admin'
import React from 'react'

const SellerInfoTab = (props) => {
  return (
    <FormTab {...props} label="Seller Info" path="seller-info">
      <ReferenceField source="sellerId" reference="sellers" label="Username">
        <TextField source="username" />
      </ReferenceField>
      <TextInput source="seller.companyName" label="Company Name" />
      <TextInput source="seller.email" label="Email" />
      <TextInput source="seller.phone" label="Phone" />
      <TextInput source="seller.address1" label="Address Line 1" />
      <TextInput source="seller.address2" label="Address Line 2" />
      <TextInput source="seller.city" label="City" />
      <TextInput source="seller.state" label="State" />
      <TextInput source="seller.zip" label="Zip" />
    </FormTab>
  )
}

export default SellerInfoTab
