import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  Filter,
  Edit,
  BooleanInput,
  BooleanField,
  EditButton,
  SimpleForm,
} from 'react-admin'
import DateFilters from '../../elements/DateFilters'
import Toolbar from '../../elements/Toolbar'

export const Filters = (props) => <Filter {...props}>{DateFilters}</Filter>
export const StripeTaxesList = (props) => (
  <List {...props} sort={{ field: 'createdAt', order: 'DESC' }} perPage={25} filters={<Filters />}>
    <Datagrid>
      <TextField source="display_name" label="name" />
      <TextField source="percentage" label="Percentage" />
      <TextField source="jurisdiction" label="Region" />
      <BooleanField source="isDefault" label="used for all invoices" />
      <EditButton />
    </Datagrid>
  </List>
)

export const StripeTaxesEdit = (props) => (
  <Edit {...props}>
    <SimpleForm toolbar={<Toolbar />}>
      <TextField source="_id" label="Id" />
      <TextField source="id" label="Stripe Id" />
      <TextField source="percentage" label="Percentage" />
      <TextField source="jurisdiction" label="Region" />
      <TextField source="description" label="description" />
      <BooleanInput source="isDefault" label="used for all invoices" />
    </SimpleForm>
  </Edit>
)
