import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

const SummaryTable = (props) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Sales [$]</TableCell>
            <TableCell>Commission [$]</TableCell>
            <TableCell>Net [$]</TableCell>
            <TableCell>Buyer's Premium [$]</TableCell>
            <TableCell>Tax [$]</TableCell>
            <TableCell>Taxable [$]</TableCell>
            <TableCell>Subtotal [$]</TableCell>
            <TableCell>Total [$]</TableCell>
            <TableCell>Refund [$]</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{(Number(props.record.summary.sales || 0) / 100).toFixed(2)}</TableCell>
            <TableCell>{(Number(props.record.summary.commission || 0) / 100).toFixed(2)}</TableCell>
            <TableCell>{(Number(props.record.summary.net || 0) / 100).toFixed(2)}</TableCell>
            <TableCell>{(Number(props.record.summary.buyerPremium || 0) / 100).toFixed(2)}</TableCell>
            <TableCell>{(Number(props.record.summary.tax || 0) / 100).toFixed(2)}</TableCell>
            <TableCell>{(Number(props.record.summary.taxable || 0) / 100).toFixed(2)}</TableCell>
            <TableCell>{(Number(props.record.summary.bidderTotal || 0) / 100).toFixed(2)}</TableCell>
            <TableCell>{(Number(props.record.summary.sellerTotal || 0) / 100).toFixed(2)}</TableCell>
            <TableCell>{(Number(props.record.refundValue || 0) / 100).toFixed(2)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default SummaryTable
