import {
  Datagrid,
  DateField,
  ShowButton,
  Filter,
  List,
  ReferenceField,
  TextField,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin'
import React from 'react'
import DateFilters from '../../elements/DateFilters'
import PriceField from '../../elements/PriceField'

const Filters = (props) => (
  <Filter {...props}>
    <ReferenceInput
      label={'Bidder username'}
      source="bidderId"
      reference="bidders"
      sort={{
        field: 'username',
        order: 'ASC',
      }}
      filterToQuery={(searchText) => {
        return (
          searchText && {
            'username[$regex]': searchText,
            'username[$options]': 'i',
          }
        )
      }}
      allowEmpty
    >
      <AutocompleteInput optionText="username" emptyText="clear search" />
    </ReferenceInput>
    {DateFilters}
  </Filter>
)

export const BidList = (props) => (
  <List {...props} sort={{ field: 'createdAt', order: 'DESC' }} perPage={25} filters={<Filters />}>
    <Datagrid>
      <PriceField source="value" />
      <ReferenceField source="bidderId" reference="bidders">
        <TextField source="username" />
      </ReferenceField>
      <ReferenceField source="itemId" reference="items">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="maxBidId" reference="maxBids">
        <TextField source="_id" />
      </ReferenceField>
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
      <ShowButton />
    </Datagrid>
  </List>
)

export default BidList
