import { FormTab } from 'react-admin'
import React from 'react'
import RichTextInput from 'ra-input-rich-text'

const RemovalTab = (props) => {
  return (
    <FormTab {...props} label="Removal" path="removal">
      <RichTextInput source="removalDescription" label="Removal Description" />
    </FormTab>
  )
}

export default RemovalTab
