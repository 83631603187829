import { AutocompleteInput, Filter, ReferenceInput, SelectInput, TextInput } from 'react-admin'
import DateFilters from '../../../elements/DateFilters'
import ItemsDateFilter from '../../../elements/ItemsDateFilter'
import React from 'react'

export const ItemFilters = ({ showBidder, showSeller, ...props }) => {
  return (
    <Filter {...props}>
      <TextInput
        label="Lot#"
        source="lotId"
        parse={(v) => {
          if (!v) return {}
          if (v[0] === '#') return v.substr(1)
          return { $regex: v || '#' }
        }}
        format={(v) => {
          if (typeof v === 'string') return `#${v}`
          return v?.$regex
        }}
        alwaysOn
      />
      <TextInput label="Title" source="name.$regex" />
      <TextInput label="Asset Number" source="assetNumber.$regex" />
      <SelectInput
        source="status"
        key="status"
        alwaysOn
        choices={[
          { id: 'published', name: 'published' },
          { id: 'invoiced', name: 'invoiced' },
          { id: 'paid', name: 'paid' },
          { id: 'unsold', name: 'unsold' },
          { id: 'endEarly', name: 'endEarly' },
          { id: 'internal', name: 'internal' },
          { id: 'endInternal', name: 'endInternal' },
        ]}
      />
      <ReferenceInput
        label={'Category'}
        source="categoryId"
        reference="categories"
        sort={{
          field: 'name',
          order: 'ASC',
        }}
        filterToQuery={(searchText) => {
          return (
            searchText && {
              'name[$regex]': searchText,
              'name[$options]': 'i',
            }
          )
        }}
        allowEmpty
      >
        <AutocompleteInput optionText="name" emptyText="clear search" />
      </ReferenceInput>
      {showSeller ? (
        <ReferenceInput
          label={'Seller company'}
          source="sellerId"
          reference="sellers"
          sort={{
            field: 'username',
            order: 'ASC',
          }}
          filterToQuery={(searchText) => {
            return (
              searchText && {
                'username[$regex]': searchText,
                'username[$options]': 'i',
              }
            )
          }}
          allowEmpty
        >
          <AutocompleteInput optionText="username" emptyText="clear search" />
        </ReferenceInput>
      ) : null}
      {showBidder ? (
        <ReferenceInput
          label={'Bidder email'}
          source="currentBidderId"
          reference="bidders"
          sort={{
            field: 'email',
            order: 'ASC',
          }}
          filterToQuery={(searchText) => {
            return (
              searchText && {
                'email[$regex]': searchText,
                'email[$options]': 'i',
              }
            )
          }}
          allowEmpty
        >
          <AutocompleteInput optionText="email" emptyText="clear search" />
        </ReferenceInput>
      ) : null}
      <ReferenceInput
        label={'Invoice nr.'}
        source="invoiceId"
        reference="invoices"
        perPage={100000} // TODO: change to autocomplete
        allowEmpty
      >
        <SelectInput allowEmply optionText="shortId" />
      </ReferenceInput>
      {DateFilters}
      {ItemsDateFilter}
    </Filter>
  )
}
